import React, { useEffect, useRef, useState } from 'react';
import Accordion from 'react-bootstrap/Accordion';
import { sortObj } from "src/components/store/sortObj";
import AccordionItemMenu from './AccordionItemMenu';
import '../../App.css';


const MyAccordion = ({ items, getItems, activeKey }) => {
    // console.log('items', items);
    return (
        <Accordion defaultActiveKey={activeKey}>
            {sortObj(items, 'orders').map((d) => {
                return <AccordionItemMenu data={d} getItems={getItems} key={d.id} />
            })}
        </Accordion>
    );
};

export default MyAccordion;