import { configureStore } from "@reduxjs/toolkit";
import orderReducer from "../REDUX/orderSlice"
const store = configureStore({
  reducer: orderReducer,
})
export default store;

export const unique = (arr, value) => {
  return arr.reduce((o, i) => {
     if (!o.find(v => v[value] === i[value])) {
       o.push(i);
     }
     return o;
   }, []);
 }