import React, { useEffect, useRef, useState } from 'react';
import { Link, useLoaderData } from 'react-router-dom';
import AxiosInstance from 'src/components/Axios';
import { CForm, CFormLabel, CFormInput, CButton } from '@coreui/react';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import MyToastify from 'src/components/myComponents/MyToactify';
import SubscriberForm from 'src/components/subscribers/SubscriberForm';

const urlApi = '/subscribers';
const urlRouter = '/subscribers';

export async function LoaderSubscribersUpdate({ params }) {
    if (Object.keys(params).length != 0) {
        let res = await AxiosInstance.get(`${urlApi}/${params.pid}`).then((response) => {
            return response;
        });
        return res;
    };
    return false;
};

const SubscribersForm = ({ user_data }) => {

    const { data } = useLoaderData();
    const [myData, setMyData] = useState(data ? data : false);

    // useEffect(() => {
    //     console.log('myData', myData);
    // }, [myData]);

    useEffect(() => {
        if (data) {
            setMyData(data);
        }
    }, [data]);

    return (
        <>
            <ToastContainer />

            <SubscriberForm data={myData} setMyData={setMyData} page="subscribers" />
        </>
    );
};

export default SubscribersForm;