import { CCard, CCardBody, CCardHeader, CCol, CRow } from '@coreui/react';
import React, { useEffect, useState } from 'react';
import AxiosInstance from 'src/components/Axios';
import FilterSpecialties from 'src/components/filter/FilterSpecialties';

// Матеріали - перегляд за спеціальністю
const MaterialsBySpecialty = () => {

    const urlApi_1 = '/materials';
    const urlApi_3 = '/locations';

    const [selectedSpecialty, setSelectedSpecialty] = useState(null);
    const [materialsData, setMaterialsData] = useState([]);
    const [locations, setLocations] = useState([]);

    // Запит за даними підрозділу
    const getMaterialsData = async (id) => {
        try {
            const response = await AxiosInstance.get(`${urlApi_1}?specialty.id=${id}`);
            // console.log('response', response);
            setMaterialsData(response.data["hydra:member"]);
        } catch (error) {
            console.error("Помилка запиту за матеріалами", error);
        };
    };

    // Запит за всіма підрозділами
    const getLocations = async () => {
        try {
            const response = await AxiosInstance.get(`${urlApi_3}`);
            // console.log('response', response);
            setLocations(response.data["hydra:member"]);
        } catch (error) {
            console.error("Помилка запиту за підрозділами", error);
        };
    };

    // Зробити запит за підрозділами
    useEffect(() => {
        getLocations();
    }, []);

    // Зробити запит за матеріалами
    useEffect(() => {
        // console.log('selectedSpecialty', selectedSpecialty);
        if (selectedSpecialty) {
            getMaterialsData(selectedSpecialty.value)
        }
    }, [selectedSpecialty]);

    return (
        <div>
            <CCard className="mb-4">
                <CCardHeader>
                    <strong>Матеріали - перегляд за напрямком (спеціальністю)</strong>
                </CCardHeader>
                <CCardBody>
                    <CRow>
                        <CCol md={4}>
                            <FilterSpecialties setData={setSelectedSpecialty} />
                        </CCol>
                    </CRow>
                    <div>
                        {
                            (locations.length > 0) &&
                            <table className="materials-table">
                                <thead>
                                    <tr>
                                        <th>Матеріали / Підрозділи</th>
                                        {locations.map((location) => (
                                            <th key={location.id}>{location.name}</th>
                                        ))}
                                    </tr>
                                </thead>
                                <tbody>
                                    {materialsData.length > 0 ?
                                        materialsData.map(material => {
                                            return (
                                                <tr key={material.id}>
                                                    <td>{material.name}</td>
                                                    {
                                                        locations.map((location) => (
                                                            <td key={location.id}>
                                                                {material?.location && material.location.some((s) => s.id === location.id) ? '✔' : '❌'}
                                                            </td>
                                                        ))
                                                    }
                                                </tr>
                                            )
                                        })
                                        :
                                        <tr>
                                            <td colSpan={locations.length + 1}>
                                                Дані відсутні
                                            </td>
                                        </tr>
                                    }

                                </tbody>
                            </table>
                        }
                    </div>
                </CCardBody>
            </CCard>
        </div>
    );
};

export default MaterialsBySpecialty;