import { CCard, CCardBody, CCardHeader, CCol, CRow } from '@coreui/react';
import React, { useEffect, useState } from 'react';
import AxiosInstance from 'src/components/Axios';
import FilterLocations from 'src/components/filter/FilterLocations';

// Матеріали - перегляд за підрозділом
const MaterialsByLocations = () => {

    const urlApi_1 = '/materials';
    const urlApi_3 = '/specialties';

    const [selectedLocations, setSelectedLocations] = useState(null);
    const [materialsData, setMaterialsData] = useState([]);
    const [specialties, setSpecialties] = useState([]);

    // Запит за даними підрозділу
    const getMaterialsData = async (id) => {
        try {
            const response = await AxiosInstance.get(`${urlApi_1}?location.id=${id}`);
            // console.log('response', response);
            setMaterialsData(response.data["hydra:member"]);
        } catch (error) {
            console.error("Помилка запиту за матеріалами", error);
        };
    };

    // Запит за всіма спеціальностями
    const getSpecialties = async () => {
        try {
            const response = await AxiosInstance.get(`${urlApi_3}`);
            // console.log('response', response);
            setSpecialties(response.data["hydra:member"]);
        } catch (error) {
            console.error("Помилка запиту за спеціальностями", error);
        };
    };

    // Зробити запит за спеціальностями
    useEffect(() => {
        getSpecialties();
    }, []);

    // Зробити запит за матеріалами
    useEffect(() => {
        // console.log('selectedLocations', selectedLocations);
        if (selectedLocations) {
            getMaterialsData(selectedLocations.value)
        }
    }, [selectedLocations]);

    return (
        <div>
            <CCard className="mb-4">
                <CCardHeader>
                    <strong>Матеріали - перегляд за підрозділом</strong>
                </CCardHeader>
                <CCardBody>
                    <CRow>
                        <CCol md={4}>
                            <FilterLocations setData={setSelectedLocations} />
                        </CCol>
                    </CRow>
                    <div>
                        {
                            (specialties.length > 0) &&
                            <table className="materials-table">
                                <thead>
                                    <tr>
                                        <th>Матеріали / Спеціальності</th>
                                        {specialties.map((specialty) => (
                                            <th key={specialty.id}>{specialty.name}</th>
                                        ))}
                                    </tr>
                                </thead>
                                <tbody>
                                    {materialsData.length > 0 ?
                                        materialsData.map(material => {
                                            return (
                                                <tr key={material.id}>
                                                    <td>{material.name}</td>
                                                    {
                                                        specialties.map((specialty) => (
                                                            <td key={specialty.id}>
                                                                {material?.specialty && material.specialty.some((s) => s.id === specialty.id) ? '✔' : '❌'}
                                                            </td>
                                                        ))
                                                    }
                                                </tr>
                                            )
                                        })
                                        :
                                        <tr>
                                            <td colSpan={specialties.length + 1}>
                                                Дані відсутні
                                            </td>
                                        </tr>
                                    }

                                </tbody>
                            </table>
                        }
                    </div>
                </CCardBody>
            </CCard>
        </div>
    );
};

export default MaterialsByLocations;