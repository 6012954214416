import React, { useEffect, useState } from 'react';
import { Table } from 'react-bootstrap';
import { Link, useLoaderData } from 'react-router-dom';
import AxiosInstance from 'src/components/Axios';
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import ProductInformationCategories from 'src/components/product/ProductInformation/ProductInformationCategories';
import ProductInformationInfo from 'src/components/product/ProductInformation/ProductInformationInfo';
import ProductInformationAttributes from 'src/components/product/ProductInformation/ProductInformationAttributes';
import { CButton } from '@coreui/react';
import StorageBalance from 'src/components/product/StorageBalance';

const urlApi_1 = '/products';
const urlRouter = '/products';
const cabinetLink = process.env.REACT_APP_SITE_URL;
const is1C = (process.env.REACT_APP_IS_1C === 'true')

export async function LoaderProductInformation({ params }) {
    if (Object.keys(params).length != 0) {
        let res = await AxiosInstance.get(`${urlApi_1}/${params.pid}`, {params: {
            "g[0]": "product:read",
            "g[1]": "product:admin",
            "g[2]": "product:productInfos",
            "g[3]": "product:attributeItems",
            "g[4]": "product:category",
        }}).then((response) => {
            // console.log("get /api/products/ response", response);
            return response;
        });
        return res;
    }
    return false;
}

// Інформація про товар (тільки для перегляду)
const ProductInformation = () => {

    const { data } = useLoaderData();
    // useEffect(() => {
    //     console.log('data', data);
    // }, [data]);

    const [name, setName] = useState((data != undefined) ? data.name : '');
    const [description, setDescription] = useState((data != undefined) ? data.description : '');
    const [code1c, setCode1c] = useState((data != undefined) ? data.code1c : '');
    const [barcode, setBarcode] = useState((data != undefined) ? data.barcode : '');
    const [status, setStatus] = useState((data != undefined) ? data.status : '');
    const [price, setPrice] = useState((data != undefined) ? data.price : '');
    const [discounts, setDiscounts] = useState((data != undefined) ? data.discounts : '');
    const [measurement_unit, setMeasurement_unit] = useState((data != undefined) ? data.measurement_unit : '');
    const [top, setTop] = useState((data != undefined) ? data.top : '0');
    const [show, setShow] = useState((data != undefined && data.show != null) ? data.show : false);
    const [article, setArticle] = useState((data != undefined) ? data.article : '');
    const [nds, setNds] = useState((data != undefined) ? data.nds : '');
    const [packaging, setPackaging] = useState((data != undefined) ? data.packaging : '');
    const [media, setMedia] = useState((data.media != undefined && data.media.length > 0) ? data.media : '');
    const [productToOrder, setProductToOrder] = useState((data != undefined) ? data.productToOrder : '0');
    const [storageElement, setStorageElement] = useState((data != undefined && data.storageElement != null) ? data.storageElement : "");
    const [userRole, setUserRole] = useState(false);
    // Головне зображення для продукта
    const [mainMedia, setMainMedia] = useState(data != undefined ? data.mainMedia : false);
    // Індекс головного зображення з масиву media
    const [indexMainMedia, setIndexMainMedia] = useState(0);

    // Взяти роль авторизованого користувача
    useEffect(() => {
        let role = localStorage.getItem('user_role');
        // console.log('role', role);
        if (role === "ROLE_ADMIN") {
            setUserRole(role);
        }
    }, []);

    // Визначити індекс головного зображення з масиву media
    useEffect(() => {
        // console.log('media', media);
        // console.log('mainMedia', mainMedia);
        if (media != undefined && media.length > 0 && mainMedia != undefined) {
            const index = media.findIndex(item => item.id === mainMedia.id);
            // console.log('index', index);
            if (index !== -1) {
                setIndexMainMedia(index);
            }
        }
    }, [media, mainMedia]);

    return (
        <div>
            <h1 className="mb-3">{name}</h1>

            {/* Дозволити редагувати товар тільки ROLE_ADMIN */}
            {
                (userRole !== false && userRole === "ROLE_ADMIN") &&
                <Link to={`${urlRouter}/update/${data.id}`} className="mb-3">
                    <CButton className="mb-3" color="secondary" title="Редагувати товар">Редагувати</CButton>
                </Link>
            }

            {/* Перейти на товар на сайті */}
            <Link to={`${cabinetLink}/${data.language?.key || 'ua'}/clients/sugar/${data.id}`} className="mb-3 ms-3" target='_blank'>
                <CButton className="mb-3" color="secondary" title="Перейти на товар на сайті">Перейти на сайт</CButton>
            </Link>

            <div style={{ maxWidth: "400px" }}>
                <Carousel
                    style={{ height: '200px', width: 'auto' }}
                    showArrows={false}
                    showThumbs={true}
                    showStatus={false}
                    thumbWidth={80}
                    selectedItem={indexMainMedia}
                >
                    {
                        // Відобразити всі зображення до продукту
                        (media != '') &&
                        media.map(el => {
                            return (
                                <div key={el.id}>
                                    <img className="me-3" src={(media != '') ? process.env.REACT_APP_SERVER_URL + '' + el.contentUrl : ''} />
                                </div>
                            )
                        })
                    }
                </Carousel>
            </div>

            <Table striped bordered hover>
                <thead>
                    <tr>
                        <th className='products-table__th1'>#</th>
                        <th className='products-table__th2'>Назва</th>
                        <th>Значення</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td className='products-table__td1'>1</td>
                        <td>Назва товару</td>
                        <td>{name}</td>
                    </tr>
                    {is1C && <tr>
                            <td className='products-table__td1'>2</td>
                            <td>Товар продається</td>
                            <td>{show ? "Так" : "Ні"}</td>
                        </tr>}
                    {is1C && <tr>
                        <td className='products-table__td1'>3</td>
                        <td>Хіт продаж</td>
                        <td>{top ? "Так" : "Ні"}</td>
                    </tr>}
                    {is1C && <tr>
                        <td className='products-table__td1'>4</td>
                        <td>Товар під замовлення</td>
                        <td>{productToOrder ? "Так" : "Ні"}</td>
                    </tr>}
                    {is1C && <tr>
                        <td className='products-table__td1'>5</td>
                        <td>Елемент Складу</td>
                        <td>{storageElement.name}</td>
                    </tr>}
                    <tr>
                        <td className='products-table__td1'>6</td>
                        <td>Опис</td>
                        <td dangerouslySetInnerHTML={{ __html: description }}></td>
                    </tr>
                    {is1C && <tr>
                        <td className='products-table__td1'>7</td>
                        <td>code1c</td>
                        <td>{code1c}</td>
                    </tr>}
                    {is1C && <tr>
                        <td className='products-table__td1'>8</td>
                        <td>Штрихкод</td>
                        <td>{barcode}</td>
                    </tr>}
                    {is1C && <tr>
                        <td className='products-table__td1'>9</td>
                        <td>Статус</td>
                        <td>{status}</td>
                    </tr>}
                    {is1C && <tr>
                        <td className='products-table__td1'>10</td>
                        <td>Ціна</td>
                        <td>{price}</td>
                    </tr>}
                    {is1C && <tr>
                        <td className='products-table__td1'>11</td>
                        <td>Знижки</td>
                        <td>{discounts}</td>
                    </tr>}
                    {is1C && <tr>
                        <td className='products-table__td1'>12</td>
                        <td>Артикул</td>
                        <td>{article}</td>
                    </tr>}
                    {is1C && <tr>
                        <td className='products-table__td1'>13</td>
                        <td>nds</td>
                        <td>{nds}</td>
                    </tr>}
                    {is1C && <tr>
                        <td className='products-table__td1'>14</td>
                        <td>packaging</td>
                        <td>{packaging}</td>
                    </tr>}
                    {is1C && <tr>
                        <td className='products-table__td1'>15</td>
                        <td>Одиниці виміру</td>
                        <td>{(measurement_unit != null || measurement_unit != undefined) ? measurement_unit.short_name : ""}</td>
                    </tr>}
                </tbody>
            </Table>
            {is1C && <StorageBalance product={data} />}
            {/* Інформація про товар */}
            <ProductInformationInfo productId={data.id} />

            {/* Атрибути */}
            <ProductInformationAttributes attributeItems={data.attributeItems} />

            {/* Категорії */}
            <ProductInformationCategories productId={data.id} />
        </div>
    );
};

export default ProductInformation;