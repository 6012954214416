import React, { useEffect, useRef, useState } from 'react';
import { CForm, CFormLabel, CFormInput, CButton, CCard, CCardBody, CCardHeader, CCol, CRow, CFormCheck, CNav, CNavItem, CNavLink } from '@coreui/react';
import Select from 'react-select';
import { Editor } from '@tinymce/tinymce-react';
import AxiosInstance from '../Axios';

// Вкладка "Загальні"
const ProductGeneral = (props) => {

    const [name, setName] = useState((props.data != undefined) ? props.data.name : '');
    const [code1c, setCode1c] = useState((props.data != undefined) ? props.data.code1c : '');
    const [barcode, setBarcode] = useState((props.data != undefined) ? props.data.barcode : '');
    const [status, setStatus] = useState((props.data != undefined) ? props.data.status : '');
    const [article, setArticle] = useState((props.data != undefined) ? props.data.article : '');
    const [packaging, setPackaging] = useState((props.data != undefined) ? props.data.packaging : '');
    const [nds, setNds] = useState((props.data != undefined) ? props.data.nds : '');
    const [show, setShow] = useState((props.data != undefined && props.data.show != null) ? props.data.show : false);

    const [storageElement, setStorageElement] = useState((props.data != undefined && props.data.storageElement != null) ? { label: props.data.storageElement.name, value: props.data.storageElement.id } : {});
    const [defaultStorageElement, setDefaultStorageElement] = useState([]);


    const [measurmentUnit, setMeasurmentUnit] = useState((props.data != undefined && props.data.measurement_unit != null) ? { label: props.data.measurement_unit.name, value: props.data.measurement_unit.id } : {});
    const [defaultMeasurmentUnit, setDefaultMeasurmentUnit] = useState([]);

    const editorRef = useRef(null);
    // Ключ для Editor
    const apiKey = process.env.REACT_APP_TINYMCE_API_KEY;

    // useEffect(() => {
    //     if (editorRef.current) {
    //         props.setDescription(editorRef.current.getContent());
    //     }
    //     // }, [editorRef.current]);
    // }, [editorRef]);

    // useEffect(() => {
    //     console.log(description);
    // }, [description]);

    // const handleEditorChange = (content, editor) => {
    //     props.setDescription(content);
    // };

    // useEffect(() => {
    //     // if (props.editorRef.current) {
    //     if (editorRef.current) {
    //         // props.editorRef.current.setContent(description);
    //         editorRef.current.setContent(props.description);
    //     }
    // }, [props.description]);

    // useEffect(() => {
    //     if (editorRef.current && props.description !== editorRef.current.getContent()) {
    //         editorRef.current.setContent(props.description);
    //     }
    // }, [props.description]);


    // Функція для відправлення форми
    // const sendForm = (event) => {
    //     if (editorRef.current) {
    //         props.setDescription(editorRef.current.getContent());
    //         setTimeout(() => {
    //             props.handleSubmit(event, "діні");
    //         }, 1000);
    //     }
    // };

    const handleChange = (value) => {
        setStorageElement(value);
    };

    // Для зміни значеь "Одиниці виміру"
    const handleChangeMeasurmentUnit = (value) => {
        setMeasurmentUnit(value);
    };

    const data_response = {
        active: 'on',
        name: name,
        // description: editorRef.current ? editorRef.current.getContent() : "",
        code1c: code1c,
        barcode: barcode,
        // status: status,
        article: article,
        show: show,
        // nds: parseFloat(nds),
        // packaging: parseFloat(packaging),
        measurement_unit: (measurmentUnit.value != undefined) ? `/api/measurment_units/${measurmentUnit.value}` : null,
        storageElement: (storageElement.value != undefined) ? `/api/storage_elements/${storageElement.value}` : null
    };

    // useEffect(() => {
    //     console.log('data_response.description', data_response.description);
    // }, [data_response]);

    useEffect(() => {
        storageElementList();
        measurmentUnitList();
    }, []);

    const storageElementList = async (inputValue) => {
        let rez = await AxiosInstance.get(`/storage_elements`, { headers: { 'accept': 'application/json' } }).then((response) => {
            // console.log("response", response);
            return response.data.map((result) => ({
                label: result.name,
                value: result.id,
                contacts: result.contacts
            }));
        });
        setDefaultStorageElement(rez);
        return rez;
    };

    // Запит за одиницями виміру
    const measurmentUnitList = async (inputValue) => {
        let rez = await AxiosInstance.get(`/measurment_units`, { headers: { 'accept': 'application/json' } }).then((response) => {
            // console.log("response", response);
            return response.data.map((result) => ({
                label: result.name,
                value: result.id,
            }));
        });
        setDefaultMeasurmentUnit(rez);
        return rez;
    };
    
    return (
        <div>
            <CForm>
            <div className="mb-3">
                    <CFormLabel htmlFor="exampleFormControlInput1">Відображення товару</CFormLabel>
                    <CFormCheck id="exampleFormControlInput8" label="Товар продається" checked={show} onChange={() => setShow(!show)} />
                </div>
                <div className="mb-3">
                    <CFormLabel htmlFor="exampleFormControlInput1">Назва</CFormLabel>
                    {/* <CFormInput type="text" id="exampleFormControlInput1" value={props.name} onChange={(e) => props.setName(e.target.value)} autoComplete="off" /> */}
                    <CFormInput type="text" id="exampleFormControlInput1" value={name} onChange={(e) => setName(e.target.value)} autoComplete="off" required />
                </div>
                {props.is1C && <div className='row'>
                <div className='col'>
                        <div className="mb-3" style={{ zIndex: "101", position: "relative" }}>
                            <CFormLabel htmlFor="exampleFormControlInput1">Елемент Складу</CFormLabel>
                            <Select
                                options={defaultStorageElement}
                                value={storageElement}
                                onChange={(value) => { handleChange(value) }}
                                required
                            />
                        </div>
                    </div>
                <div className='col'>
                        <div className="mb-3" style={{ zIndex: "101", position: "relative" }}>
                            <CFormLabel htmlFor="exampleFormControlInput1">Одиниці виміру</CFormLabel>
                            <Select
                                options={defaultMeasurmentUnit}
                                value={measurmentUnit}
                                onChange={(value) => { handleChangeMeasurmentUnit(value) }}
                                required
                            />
                        </div>
                    </div>
                </div>}

                {props.is1C && <div className='row'>
                    <div className='col'>
                        <div className="mb-3">
                            <CFormLabel htmlFor="exampleFormControlInput1">Артикул</CFormLabel>
                            <CFormInput type="number" id="exampleFormControlInput9" value={article} onChange={(e) => setArticle(e.target.value)} autoComplete="off" />
                        </div>
                    </div>
                    <div className='col'>
                        <div className="mb-3">
                            <CFormLabel htmlFor="exampleFormControlInput1">code1c</CFormLabel>
                            <CFormInput type="text" id="exampleFormControlInput3" value={code1c} onChange={(e) => setCode1c(e.target.value)} autoComplete="off" />
                        </div>
                    </div>
                    <div className='col'>
                        <div className="mb-3">
                            <CFormLabel htmlFor="exampleFormControlInput1">Штрихкод</CFormLabel>
                            <CFormInput type="text" id="exampleFormControlInput4" value={barcode == null ? "" : barcode} onChange={(e) => setBarcode(e.target.value)} autoComplete="off" />
                        </div>
                    </div>

                </div>}

                {/* <div className="mb-3">
                          <CFormLabel htmlFor="exampleFormControlInput1">nds</CFormLabel>
                          <CFormInput type="number" id="exampleFormControlInput10" value={nds} onChange={(e) => setNds(e.target.value)} autoComplete="off" />
                        </div> */}
                {/* <div className="mb-3">
                          <CFormLabel htmlFor="exampleFormControlInput1">packaging</CFormLabel>
                          <CFormInput type="number" id="exampleFormControlInput11" value={packaging} onChange={(e) => setPackaging(e.target.value)} autoComplete="off" />
                        </div> */}
                <div className="mb-3" style={{ zIndex: "100", position: "relative" }}>
                    <Editor
                        apiKey={apiKey}
                        onInit={(evt, editor) => editorRef.current = editor}
                        // initialValue={(props.data != undefined) ? props.data.description : ''}
                        initialValue={(props.MyData != undefined) ? props.MyData.description : ''}
                        init={{
                            height: 500,
                            menubar: false,
                            plugins: [
                                'advlist autolink lists link image charmap print preview anchor',
                                'searchreplace visualblocks code fullscreen',
                                'insertdatetime media table paste code help wordcount'
                            ],
                            toolbar: 'undo redo | formatselect | ' +
                                'bold italic backcolor | alignleft aligncenter ' +
                                'alignright alignjustify | bullist numlist outdent indent | ' +
                                'removeformat | help',
                            content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
                        }}
                    />
                </div>
                <CButton className="mb-3" color="success" 
                    onClick={() => {
                        props.handleSubmit(event, {...data_response, description: editorRef.current.getContent()})
                    }}
                >Зберегти товар</CButton>
            </CForm>
        </div >
    );
};

export default ProductGeneral;