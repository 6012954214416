import React, { useEffect, useState } from 'react';
import { CFormCheck, CButton } from '@coreui/react';
import AxiosInstance from '../Axios';

// Список матеріалів
const MaterialsList = (props) => {
    // console.log('MaterialsList props', props);

    const urlApi_1 = '/materials';
    const urlApi_2 = '/events';

    const [materials, setMaterials] = useState([]); // Список матеріалів
    const [checkedMaterials, setCheckedMaterials] = useState([]); // Список матеріалів з checked

    const popupTextAddSuccess = "Додані матеріали змінено";
    const popupTextDeleteSuccess = "Додані матеріали не змінено";

    // Запит за матеріалами
    const getMaterials = async () => {
        try {
            const response = await AxiosInstance.get(urlApi_1);
            // console.log('response', response);
            setMaterials(response.data["hydra:member"]);
        } catch (error) {
            console.error("Помилка запиту за матеріалами", error);
        };
    };

    // Функція для оновлення матеріалів в події
    const putEvent = async () => {
        let article = {
            materials: checkedMaterials
        };
        try {
            const response = await AxiosInstance.put(`${urlApi_2}/${props.data.id}`, article);
            // console.log("materials put response", response);
            props.setMyData(response.data);
            // Popup про успішні зміни
            props.toastifySuccess(popupTextAddSuccess);
        } catch (error) {
            // Popup про успішні зміни
            props.toastifySuccess(popupTextDeleteSuccess);
            console.error("Додані матеріали не змінено", error);
        };
    };

    // Зробити запит за всіма матеріалами
    useEffect(() => {
        getMaterials();
    }, []);

    // Відслідковувати для яких матеріалів додана подія у форматі IRI
    useEffect(() => {
        const initialCheckedMaterials = props.data.materials.map(spec => `/api/materials/${spec.id}`);
        // console.log('initialCheckedMaterials ', initialCheckedMaterials );
        setCheckedMaterials(initialCheckedMaterials);
    }, [props.data.materials]);

    // Обробка зміни стану чекбоксу
    const handleCheckboxChange = (id) => {
        setCheckedMaterials((prevState) => {
            const materialsIRI = `/api/materials/${id}`;
            if (prevState.includes(materialsIRI)) {
                // Видалити з масиву, якщо було відмічено
                return prevState.filter(spec => spec !== materialsIRI);
            } else {
                return [...prevState, materialsIRI];
            };
        });
    };

    return (
        <div>
            <h3 className="mb-2">Матеріали</h3>

            {
                materials.length > 0 &&
                <>
                    {
                        materials.map((specialty) => {
                            // console.log('specialty', specialty);
                            const isChecked = checkedMaterials.includes(`/api/materials/${specialty.id}`);

                            return (
                                <CFormCheck className="mb-2"
                                    id={`specialty-${specialty.id}`}
                                    label={specialty.name} key={specialty.id}
                                    checked={isChecked}
                                    onChange={() => handleCheckboxChange(specialty.id)}
                                />
                            );
                        })
                    }
                    <div className="mb-2">
                        <CButton color="success" onClick={() => putEvent()}>Зберегти</CButton>
                    </div>
                </>
            }
        </div >
    );
};

export default MaterialsList;