import React, { useEffect, useState } from 'react';
import BasicModal from 'src/components/Modal';
import EventsSubscribersList from './EventsSubscribersList';
import SubscriberForm from 'src/components/subscribers/SubscriberForm';
import AxiosInstance from 'src/components/Axios';

// Контейнер для ачасників події
const EventsSubscribersContainer = (props) => {
    // console.log('EventsSubscribersContainer props', props);

    const urlApi_1 = "/subscribers";

    const [isShowModalList, setIsShowModalList] = useState(false); // Для модального вікна "Список учасників"
    const [isShowModalForm, setIsShowModalForm] = useState(false); // Для модального вікна "Додати учасника"
    const [subscriberId, setSubscriberId] = useState(null);
    const [subscriber, setSubscriber] = useState(null);

    // Функція для запиту за учасником
    const getSubscriber = async (id) => {
        try {
            const response = await AxiosInstance.get(`${urlApi_1}/${id}`);
            // console.log('subscribers get response', response);
            setSubscriber(response.data);
        } catch (error) {
            console.error("Помилка запиту за учасником", error);
        }
    };

    // Для модального вікна "Список учасників"
    const actionShowModalList = (rez) => {
        setIsShowModalList(rez);
    };

    // Для модального вікна "Додати учасника"
    const actionShowModalForm = (rez) => {
        setIsShowModalForm(rez);
    };

    // Відслідковувати чи вибраний учасник з списку
    useEffect(() => {
        // console.log('subscriberId', subscriberId);
        if (subscriberId) {
            actionShowModalList(false);
            getSubscriber(subscriberId);
        };
    }, [subscriberId]);

    // Коли є дані по учакнику, то відкрити модальне вікно з формою
    useEffect(() => {
        // console.log('subscriber', subscriber);
        if (subscriber) {
            setIsShowModalForm(true);
        };
    }, [subscriber]);

    // При закриванні модального вікна з формою учасника потрібно обнулити дані
    useEffect(() => {
        if (!isShowModalForm) {
            setSubscriberId(null);
            setSubscriber(null);
        };
    }, [isShowModalForm])

    return (
        <div>
            <h3 className="mb-2">Учасники події</h3>
            <div className="d-flex">
                <span className="me-2">
                    <BasicModal
                        show={isShowModalList}
                        actionShowModal={actionShowModalList}
                        title={`Список учасників події "${props?.data?.name}"`}
                        content={
                            <EventsSubscribersList setIsShowModalList={setIsShowModalList} eventId={props.data.id} setSubscriberId={setSubscriberId} />
                        }
                        btn_name="Список учасників"
                        type="events"
                    />
                </span>
                <span>
                    <BasicModal
                        show={isShowModalForm}
                        actionShowModal={actionShowModalForm}
                        title={'Додати учасника'}
                        content={
                            <SubscriberForm
                                setIsShowModalForm={setIsShowModalForm} page="events" eventId={props.data.id}
                                data={subscriber} setMyData={setSubscriber}
                            />
                        }
                        btn_name="Додати учасника"
                    />
                </span>
            </div>
        </div>
    );
};

export default EventsSubscribersContainer;