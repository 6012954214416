import { useEffect, useState } from "react";
import MediaFrom from "../media/MediaFrom";
import {
    CForm,
    CFormLabel,
    CFormInput,
    CButton
  } from '@coreui/react';
import AxiosInstance from "../Axios";



const AddItemGallery = ({toastifySuccess, data, parent_id, actionShowModal }) => {
    const [media, setMedia] = useState(false);
    const [name, setName] = useState('');
    const [content, setContent] = useState('');
    const [mediaData, setMediaData] = useState(false);
    useEffect(() => {
        AxiosInstance.get(`/media_objects/${media}`).then((responce) => {
            if(responce.status == 200){
                setMediaData(responce.data);
            }
        });
    }, [media]);

    const handleSubmit = event => {
        event.preventDefault();
        
          const article = {
            name: name,
            content: content,
            gallery: `/api/galleries/${parent_id}`,
            media: `/api/media_objects/${media}`,
          };
          if (data != undefined){
            const response = AxiosInstance.put('/gallery_items/' + data.id , article).then((response) => {
              if(response.status == 200){
                toastifySuccess('Галерею оновлено');
                actionShowModal(false);
              }
            });
          }else {
            const response = AxiosInstance.post('/gallery_items', article).then((response) => {
              console.log(response);
              if (response.status == 201) {
                actionShowModal(false);
                toastifySuccess("Галерею створено")
                
              }
            });
          }
        
    }
    return (<>
        {(mediaData) && <img src={process.env.REACT_APP_SERVER_URL + mediaData.contentUrl} with="300px" />}
        <MediaFrom type="category"  setNewMediaId={setMedia} toastifySuccess={toastifySuccess} />
        <CForm onSubmit={handleSubmit}>
          <div className="mb-3">
            <CFormLabel htmlFor="exampleFormControlInput1">Назва</CFormLabel>
            <CFormInput type="text" id="exampleFormControlInput1" value={name} onChange={(e) => setName(e.target.value)} />
          </div>
          <div className="mb-3">
            <CFormLabel htmlFor="exampleFormControlInput1">Контент</CFormLabel>
            <CFormInput type="text" id="exampleFormControlInput1" value={content} onChange={(e) => setContent(e.target.value)} />
          </div>
          <CButton type="submit" color="success">Submit</CButton>
          
        </CForm>
    </>);
}

export default AddItemGallery;