import React, { useRef, useState } from 'react';
import {
  CForm,
  CFormLabel,
  CFormInput,
  CButton,
  CAlert
} from '@coreui/react';
import {useLoaderData} from 'react-router-dom';
import axios from "axios";
import { Editor } from '@tinymce/tinymce-react';
import PermissionsNoPage, {Permissions} from "src/components/Permissions";

const tokenStr = localStorage.getItem('user');
const header = { headers: {"Authorization" : `Bearer ${tokenStr}`} };

export async function LoaderLanguagesUpdate({ params }) {
  if(Object.keys(params).length != 0) {
    let res = await axios.get(process.env.REACT_APP_SERVER_URL + '/api/languages/' + params.optionId, header).then((response) => {
      return response;
    });
    return res;
  }
  return  false;
}
const LanguagesForm = ({user_data}) => {
    if(!Permissions(user_data, 'Languages', 'EDIT_LANGUAGES')){
        return <PermissionsNoPage />;
    }
    const {data}  = useLoaderData();
    const [name, setName] = useState((data != undefined)? data.name : '');
    const [key, setKey] = useState((data != undefined)? data.optionKey : '');
    const [value, setValue] = useState((data != undefined)? data.value : '');
    const [validated, setValidated] = useState(false)
    const [visibleSuccess, setVisibleSuccess] = useState(false)
    const [visibleError, setVisibleError] = useState(false)
    const editorRef = useRef(null);
    const handleSubmit = event => {
        event.preventDefault();
        let v = false;
        let success = false;
        const form = event.currentTarget
        if (form.checkValidity() === false) {
            event.preventDefault()
            event.stopPropagation()
        }else{
            v = true;
        }
        setValidated(true);
        

        if(v){
            const article = {
                name: name,
                key: key,
            };
           

            if (data != undefined){
                const response = axios.put(process.env.REACT_APP_SERVER_URL + '/api/languages/' + data.id , article, header).then((response) => {
                    console.log(response);
                    if(response.statusText == 'OK')
                        setVisibleSuccess(true)
                    else
                        setVisibleError(true)
                });
                
            }else {
                const response = axios.post(process.env.REACT_APP_SERVER_URL + '/api/languages', article, header).then((response) => {
                    console.log(response);
                    if(response.statusText == 'Created')
                        setVisibleSuccess(true)
                    else
                        setVisibleError(true)
                });
            }
        }
    
    }


    return (
        <>
        <CAlert color="success" dismissible visible={visibleSuccess} onClose={() => setVisibleSuccess(false)}>Дані збережено!</CAlert>
        <CAlert color="danger" dismissible visible={visibleError} onClose={() => setVisibleError(false)}>Дані не збережено!</CAlert>
          <CForm onSubmit={handleSubmit}
            className="row g-3 needs-validation"
            noValidate
            validated={validated}
            >
            <div className="mb-3">
              <CFormLabel htmlFor="exampleFormControlInput1">Назва</CFormLabel>
              <CFormInput type="text" id="exampleFormControlInput1" value={name} onChange={(e) => setName(e.target.value)} feedbackValid="" required />
            </div>
            <div className="mb-3">
              <CFormLabel htmlFor="exampleFormControlInput1">Ключ</CFormLabel>
              <CFormInput type="text" id="exampleFormControlInput2" value={key} onChange={(e) => setKey(e.target.value)} feedbackValid="" required />
            </div>
           
            <CButton type="submit" color="success">Зберегти</CButton>
          </CForm>
        </>
      );

}

export default LanguagesForm;