import { useEffect, useState } from "react";
import { Carousel } from "react-bootstrap";
import AxiosInstance from "../Axios";

const HeaderSlider = ({id}) => {
        const [index, setIndex] = useState(0);
        const [slider, setSlider] = useState(false);
      
        const handleSelect = (selectedIndex, e) => {
          setIndex(selectedIndex);
        };


        

        useEffect(() => {
            if(id != undefined){
                AxiosInstance.get(`/sliders/${id}`).then((responce) =>{
                    if(responce.status == 200){
                        setSlider(responce.data);
                    }
                });
            }
        }, [id])

        const Logo = process.env.REACT_APP_LOGO;

      
        return (
          <Carousel activeIndex={index} onSelect={handleSelect}>
            { (id == undefined || !slider)? 
                    <Carousel.Item>
                    <img
                        className="d-block w-100"
                        src={Logo}
                        alt="First slide"
                        style={{backgroundColor:'black'}}
                    />
                    <Carousel.Caption>
                        <h3>First slide label</h3>
                        <p>Nulla vitae elit libero, a pharetra augue mollis interdum.</p>
                    </Carousel.Caption>
                    </Carousel.Item>
            :
               
                slider.slides.map((s) => {
                    // console.log(s);
                    return(
                        <Carousel.Item key={s.id}>
                            <img
                                className="d-block w-100"
                                src={process.env.REACT_APP_SERVER_URL + s.media.contentUrl}
                                alt="First slide"
                                style={{backgroundColor:'black'}}
                            />
                            <Carousel.Caption>
                                <h3>{s.name}</h3>
                                <p>{s.content}</p>
                            </Carousel.Caption>
                        </Carousel.Item>
                    );
                })
                
            }
           
          </Carousel>
        );
      
}

export default HeaderSlider;