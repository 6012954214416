/* eslint-disable react/prop-types */
/* eslint-disable prettier/prettier */
/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import { CForm, CFormLabel, CFormInput, CButton } from '@coreui/react';
import AxiosInstance from '../Axios';

const EditProductInfo = ({ product, actionShowModal, toastifySuccess, toastifyWarning }) => {

    const urlApi = `/product_infos/${product.id}`;
    // Текст для popup
    const popupTextSuccess = "Інформація оновлена";
    const popupTextWarning = "Помилка при оновленні інформації";

    const [values, setValues] = useState({
        name: product.name,
        value: product.value,
        sort: parseInt(product.sort),
    });

    const handleChange = (value, name) => {
        setValues({
            ...values,
            [name]: value
        });
    };

    const handleSubmit = event => {
        event.preventDefault();
        // Оновити інформацію про товар
        AxiosInstance.put(urlApi, values).then((response) => {
            // Popup про успішні зміни
            toastifySuccess(popupTextSuccess);
            actionShowModal(false, true);
        }).catch(() => {
            toastifyWarning(popupTextWarning);
        });
    };

    return (
        <>
            <CForm onSubmit={handleSubmit}>
                <div className='row'>
                    <div className='col-4'>
                        <div className="mb-3">
                            <CFormLabel htmlFor="exampleFormControlInput1">Назва</CFormLabel>
                            <CFormInput disabled type="text" id="exampleFormControlInput2" value={values.name} onChange={(e) => handleChange(e.target.value, 'name')} />
                        </div>
                    </div>
                    <div className='col-4'>
                        <div className="mb-3">
                            <CFormLabel htmlFor="exampleFormControlInput1">Значення</CFormLabel>
                            <CFormInput type="text" id="exampleFormControlInput2" value={values.value} onChange={(e) => handleChange(e.target.value, 'value')} />
                        </div>
                    </div>
                    <div className='col-4'>
                        <div className="mb-3">
                            <CFormLabel htmlFor="exampleFormControlInput1">Сортування</CFormLabel>
                            <CFormInput type="text" id="exampleFormControlInput2" value={values.sort} onChange={(e) => handleChange(parseInt(e.target.value) || 0, 'sort')} />
                        </div>
                    </div>
                </div>
                <CButton type="submit" color="success">Зберегти</CButton>
            </CForm>
        </>
    )
};

export default EditProductInfo;