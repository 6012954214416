import React, { useEffect, useRef, useState } from 'react';
import { CForm, CFormLabel, CFormInput, CButton, CAlert } from '@coreui/react';
import { useLoaderData, useNavigate } from 'react-router-dom';
import { Editor } from '@tinymce/tinymce-react';
import AxiosInstance from 'src/components/Axios';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import MyToastify from 'src/components/myComponents/MyToactify';
import PermissionsNoPage, {Permissions} from "src/components/Permissions";

const urlApi = '/options';
const urlRouter = '/options';

export async function LoaderOptionUpdate({ params }) {
  if (Object.keys(params).length != 0) {
    let res = await AxiosInstance.get(`${urlApi}/${params.optionId}`).then((response) => {
      return response;
    });
    return res;
  }
  return false;
}

const OptionForm = ({user_data}) => {
  if(!Permissions(user_data, 'Options', 'EDIT_OPTIONS')){
      return <PermissionsNoPage />;
  }
  const { data } = useLoaderData();
  const [name, setName] = useState((data != undefined) ? data.name : '');
  const [key, setKey] = useState((data != undefined) ? data.optionKey : '');
  const [value, setValue] = useState((data != undefined) ? data.value : '');
  const [validated, setValidated] = useState(false)
  const [visibleSuccess, setVisibleSuccess] = useState(false)
  const [visibleError, setVisibleError] = useState(false)
  const editorRef = useRef(null);
  const navigate = useNavigate();
  const [myData, setMyData] = useState((data != undefined) ? data : false);

  useEffect(() => {
    setMyData(data);
  }, [data]);

  // Викликає popup з власного компонента
  const popup = MyToastify();
  // Текст для popup
  const popupTextPutSuccess = "Оновлено";
  const popupTextPostSuccess = "Створено";
  const popupTextPutWarning = "Не оновлено";
  const popupTextPostWarning = "Не створено";

  // Ключ для Editor
  const apiKey = process.env.REACT_APP_TINYMCE_API_KEY;

  const handleSubmit = event => {
    event.preventDefault();
    let v = false;
    let success = false;
    const form = event.currentTarget
    if (form.checkValidity() === false) {
      event.preventDefault()
      event.stopPropagation()
    } else {
      v = true;
    }
    setValidated(true);

    if (v) {
      const article = {
        name: name,
        optionKey: key,
        value: value,
        data: ''
      };
      if (editorRef.current) {
        let d = editorRef.current.getContent();
        if (d != '') {
          article.data = d;
        }
      }

      // Для оновлення
      if (data != undefined) {
        const response = AxiosInstance.put(`${urlApi}/${data.id}`, article).then((response) => {
          console.log(response);
          if (response.status === 200) {
            // setVisibleSuccess(true)
            popup.toastifySuccess(popupTextPutSuccess);
            setMyData(response.data);
          } else {
            // setVisibleError(true)
            popup.toastifyWarning(popupTextPutWarning);
          }
        });
      } else {
        // Для створення
        const response = AxiosInstance.post(urlApi, article).then((response) => {
          // console.log(response);
          if (response.status === 201) {
            // setVisibleSuccess(true)
            // Popup про успішні зміни
            popup.toastifySuccess(popupTextPostSuccess);
            // alert("Дані оновлено");
            navigate(`${urlRouter}/update/${response.data.id}`);
          } else {
            // setVisibleError(true)
            popup.toastifyWarning(popupTextPostWarning);
          }
        });
      }
    }
  }

  return (
    <>
      {/* Popup про успішні зміни (справа зверху) */}
      <ToastContainer />

      {/* <CAlert color="success" dismissible visible={visibleSuccess} onClose={() => setVisibleSuccess(false)}>Дані збережено!</CAlert>
      <CAlert color="danger" dismissible visible={visibleError} onClose={() => setVisibleError(false)}>Дані не збережено!</CAlert> */}

      <CForm onSubmit={handleSubmit}
        // className="row g-3 needs-validation mb-3"
        className="mb-3"
        noValidate
        validated={validated}
      >
        <div className="mb-3">
          <CFormLabel htmlFor="exampleFormControlInput1">Назва</CFormLabel>
          <CFormInput type="text" id="exampleFormControlInput1" value={name} onChange={(e) => setName(e.target.value)} feedbackValid="" autoComplete="off" required />
        </div>
        <div className="mb-3">
          <CFormLabel htmlFor="exampleFormControlInput1">Ключ</CFormLabel>
          <CFormInput type="text" id="exampleFormControlInput2" value={key} onChange={(e) => setKey(e.target.value)} feedbackValid="" autoComplete="off" required />
        </div>
        <div className="mb-3">
          <CFormLabel htmlFor="exampleFormControlInput1">Значення</CFormLabel>
          <CFormInput type="text" id="exampleFormControlInput2" value={value} onChange={(e) => setValue(e.target.value)} feedbackValid="" autoComplete="off" required />
        </div>
        <div className="mb-3">
          <Editor
            apiKey={apiKey}
            onInit={(evt, editor) => editorRef.current = editor}
            initialValue={(data != undefined) ? data.data : ''}
            init={{
              height: 500,
              menubar: 'edit view insert format',
              plugins: [
                'advlist','autolink',
                'lists','link','image','charmap','preview','anchor','searchreplace','visualblocks','fullscreen',
                'insertdatetime','media','table', 'help', 'code'
             ],
              toolbar: 'undo redo | fontfamily fontsize | fontselect | bold italic backcolor | ' +
                'alignleft aligncenter alignright alignjustify | ' +
                'bullist numlist checklist outdent indent | removeformat | link media a11ycheck code table help',
              content_style: 'body { font-family:FixelDisplayRegular; font-size:14px }',
              font_family_formats: "Andale Mono=andale mono,times; Arial=arial,helvetica,sans-serif; Arial Black=arial black,avant garde; Book Antiqua=book antiqua,palatino; Comic Sans MS=comic sans ms,sans-serif; Courier New=courier new,courier; Georgia=georgia,palatino; Helvetica=helvetica; Impact=impact,chicago; Fixel Display Regular=fixelDisplayRegular; Symbol=symbol; Tahoma=tahoma,arial,helvetica,sans-serif; Terminal=terminal,monaco; Times New Roman=times new roman,times; Trebuchet MS=trebuchet ms,geneva; Verdana=verdana,geneva; Webdings=webdings; Wingdings=wingdings,zapf dingbats",
            }}
          />
        </div>
        <CButton type="submit" color="success">Зберегти</CButton>
      </CForm>
    </>
  );
}

export default OptionForm;