import React, { useState } from 'react';
import { CCard, CCardBody, CCardHeader, CCol, CRow, CButton } from '@coreui/react';
import { DataGrid } from '@mui/x-data-grid';
import { Link, useLoaderData } from 'react-router-dom';
import AxiosInstance from 'src/components/Axios';
import { cilPen, cilPlus, cilTrash } from '@coreui/icons';
import CIcon from '@coreui/icons-react';
import { confirmAlert } from 'react-confirm-alert';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import MyToastify from 'src/components/myComponents/MyToactify';
import Badge from 'react-bootstrap/Badge'
import PermissionsNoPage, {Permissions} from "src/components/Permissions";


const urlApi = '/categories';
const urlRouter = '/category';

const page = 1;
const postPerPage = 0;
const pageSize = 20;

export async function LoaderCategory({ params }) {
  let p = {
    'main': true,
    'page': 1,
    'g[0]': 'cat:read',
    'g[1]': 'cat:categories',
    'language': 1,
  };
  let res = await AxiosInstance.get(urlApi, {params: p}).then((response) => {
    return response;
  });
  return res;
}

const Category = ({user_data}) => {
  if(!Permissions(user_data, 'Category', 'VIEW_CATEGORY')){
    return <PermissionsNoPage />;
  }
  const { data } = useLoaderData();
  const [rows, setRows] = useState(false);
  const [rowCountState, setRowCountState] = React.useState(0);
  // Викликає popup з власного компонента
  const popup = MyToastify();
  // Текст для popup
  const popupTextSuccess = "Категорію видалено";

  if (!rows) {
    setRowCountState(data['hydra:totalItems'])
    setRows(data['hydra:member']);
  }
  const getRowsTable = (p = 1) => {
    AxiosInstance.get(`${urlApi}?main=true&page=${p}&language=1`).then((response) => {
      setRows(response.data['hydra:member']);
    });
  };
  const handlePageChange = (page) => {
    getRowsTable(page + 1);
  };

  function Deleted(row) {
    confirmAlert({
      title: 'Ви впевнені що хочите видалити цей пункт ?',
      message: 'Are you sure to do this.',
      buttons: [
        {
          label: 'Так',
          onClick: () => {
            const response = AxiosInstance.delete(`${urlApi}/${row.id}`).then((response) => {
              // Popup про успішні зміни
              popup.toastifySuccess(popupTextSuccess);
              getRowsTable();
            });
          }
        },
        {
          label: 'Ні',
          onClick: () => {
            return;
          }
        }
      ]
    });
  };


  const Cats = ({data}) => (
    <>
      {data.categories.map((c) => (
        <Badge key={`cats_${c.id}`} variant="light" className='bg-light text-secondary'>{c.name}</Badge>
      ))}
    </>
  );


  const columns = [
    { field: 'id', headerName: 'ID', width: 70 },
    { field: 'name', headerName: 'Назва', width: 200 },
    { field: 'productCnt', headerName: 'К-сть тов.', width: 100 },
    {
      field: 'categories', headerName: 'Під категорії', width: 200,
      editable: true,
      renderCell: (params) => {
        
        return (params.row.categories != null &&  params.row.categories != undefined && params.row.categories.length > 0) && 
          <><Badge variant="light" className='bg-light text-secondary'>{params.row.categories.length}</Badge> <Cats data={params.row}/></>;
      }
    },
    { field: 'type', headerName: 'Тип', width: 200 },
    { field: 'slug', headerName: 'Посилання', width: 200 },
    { field: 'sort', headerName: 'Сортування', width: 100 },
    {
      field: 'created_at',
      headerName: 'Дата',
      type: 'date',
      width: 160,
    },
    {
      field: "action",
      headerName: "Action",
      sortable: false,
      renderCell: (params) => {
        return <Link to={`${urlRouter}/update/${params.row.id}`}><CButton color="dark" variant="outline"><CIcon icon={cilPen} customClassName="nav-icon" height={20} /></CButton></Link>;
      }
    },
    {
      field: "delete",
      headerName: "Видалити",
      sortable: false,
      renderCell: (params) => {
        return <CButton color="danger" variant="outline" onClick={() => {
          Deleted(params.row)
        }
        }><CIcon icon={cilTrash} customClassName="nav-icon" height={20} /></CButton>;
      }
    },
  ];

  return (
    <>
      {/* Popup про успішні зміни (справа зверху) */}
      <ToastContainer />

      <CRow>
        <CCol xs={12}>
          <CCard className="mb-4">
            <CCardHeader>
              <strong>Категорії</strong> <small></small>
            </CCardHeader>
            <CCardBody>
              <p className="text-medium-emphasis small">
                <Link to={`${urlRouter}/create`}><CButton color="secondary"><CIcon icon={cilPlus} customClassName="nav-icon" height={15} />Створити</CButton></Link>
              </p>
              <div style={{ height: 600, width: '100%' }}>
                <DataGrid
                  rows={rows}
                  columns={columns}
                  pageSize={30}
                  rowsPerPageOptions={[30]}
                  pagination
                  rowCount={rowCountState}
                  paginationMode="server"
                  onPageChange={handlePageChange}
                />
              </div>
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>
    </>
  );
};

export default Category;