import React, { useEffect, useRef, useState } from 'react';
import { Editor } from '@tinymce/tinymce-react';
import { Link, useLoaderData } from 'react-router-dom';
import AxiosInstance from 'src/components/Axios';
import { useNavigate } from "react-router-dom";
import { CForm, CFormLabel, CFormInput, CButton } from '@coreui/react';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import MyToastify from 'src/components/myComponents/MyToactify';
import LocationsList from 'src/components/locations/LocationsList';
import SpecialtyList from 'src/components/specialty/SpecialtyList';
import MaterialsMedia from 'src/components/materials/MaterialsMedia';

const urlApi = '/materials';
const urlRouter = '/materials';

export async function LoaderMaterialsUpdate({ params }) {
  if (Object.keys(params).length != 0) {
    let res = await AxiosInstance.get(`${urlApi}/${params.pid}`).then((response) => {
      return response;
    });
    return res;
  }
  return false;
};

// Форма для матеріалу
const MaterialsForm = () => {
  const navigate = useNavigate();
  const { data } = useLoaderData();
  const [myData, setMyData] = useState((data != undefined) ? data : false);
  const [name, setName] = useState((data != undefined) ? data.name : '');
  const apiKey = process.env.REACT_APP_TINYMCE_API_KEY;
  const editorRef = useRef(null);

  useEffect(() => {
    setMyData(data);
  }, [data]);

  // Викликає popup з власного компонента
  const popup = MyToastify();
  // Текст для popup
  const popupTextPutSuccess = "Матеріал оновлено";
  const popupTextPostSuccess = "Матеріал створено";
  const popupTextPutWarning = "Матеріал не оновлено";
  const popupTextPostWarning = "Матеріал не створено";

  // Функція для оновлення даних по матеріалу
  const getMaterials = async () => {
    try {
      const response = await AxiosInstance.get(`${urlApi}/${myData.id}`);
      setMyData(response.data);
    } catch (error) {
      console.error("Помилка запиту за даними по матеріалу", error);
    };
  };

  const handleSubmit = event => {
    event.preventDefault();
    const data_response = {
      name: name,
    };
    if (editorRef.current) {
      data_response['description'] = editorRef.current.getContent();
    }

    if (data != undefined) {
      const response = AxiosInstance.put(`${urlApi}/${data.id}`, data_response).then((response) => {
        if (response.status === 200) {
          popup.toastifySuccess(popupTextPutSuccess);
          setMyData(response.data);
          // return navigate(urlRouter);
        } else {
          popup.toastifyWarning(popupTextPutWarning);
        }
      });
    } else {
      const response = AxiosInstance.post(`${urlApi}`, data_response).then((response) => {
        if (response.status === 201) {
          // return navigate(urlRouter);
          // Popup про успішні зміни
          popup.toastifySuccess(popupTextPostSuccess);
          // alert("Дані оновлено");
          navigate(`${urlRouter}/update/${response.data.id}`);
        } else {
          popup.toastifyWarning(popupTextPostWarning);
        }
      });
    };
  };

  return (
    <>
      {/* Popup про успішні зміни (справа зверху) */}
      <ToastContainer />

      <CForm onSubmit={handleSubmit} className="mb-3">
        <div className="mb-3">
          <CFormLabel htmlFor="exampleFormControlInput1">Назва</CFormLabel>
          <CFormInput type="text" id="exampleFormControlInput1" value={name} onChange={(e) => setName(e.target.value)} autoComplete="off" />
        </div>
        <div className="mb-2">
          <Editor
            apiKey={apiKey}
            onInit={(evt, editor) => editorRef.current = editor}
            initialValue={(data != undefined) ? data.description : ''}
            init={{
              height: 500,
              menubar: 'edit view insert format',
              plugins: [
                'advlist', 'autolink',
                'lists', 'link', 'image', 'charmap', 'preview', 'anchor', 'searchreplace', 'visualblocks', 'fullscreen',
                'insertdatetime', 'media', 'table', 'help', 'code'
              ],
              toolbar: 'undo redo | fontfamily fontsize | fontselect | bold italic backcolor | ' +
                'alignleft aligncenter alignright alignjustify | ' +
                'bullist numlist checklist outdent indent | removeformat | link media a11ycheck code table help',
              content_style: 'body { font-family:FixelDisplayRegular; font-size:14px }',
              font_family_formats: "Andale Mono=andale mono,times; Arial=arial,helvetica,sans-serif; Arial Black=arial black,avant garde; Book Antiqua=book antiqua,palatino; Comic Sans MS=comic sans ms,sans-serif; Courier New=courier new,courier; Georgia=georgia,palatino; Helvetica=helvetica; Impact=impact,chicago; Fixel Display Regular=fixelDisplayRegular; Symbol=symbol; Tahoma=tahoma,arial,helvetica,sans-serif; Terminal=terminal,monaco; Times New Roman=times new roman,times; Trebuchet MS=trebuchet ms,geneva; Verdana=verdana,geneva; Webdings=webdings; Wingdings=wingdings,zapf dingbats",
            }}
          />
        </div>

        <CButton type="submit" color="success">Зберегти</CButton>
      </CForm>

      {/* Файли для матеріалів */}
      {
        (myData) &&
        <>
          <hr className="mb-4" />
          <MaterialsMedia
            data={myData} setMyData={setMyData}
            getMaterials={getMaterials}
            toastifySuccess={popup.toastifySuccess} toastifyWarning={popup.toastifyWarning}
          />
        </>
      }

      {/* Список підрозділів */}
      {
        (myData) &&
        <>
          <hr className="mb-4" />
          <LocationsList data={myData} setMyData={setMyData} toastifySuccess={popup.toastifySuccess} toastifyWarning={popup.toastifyWarning} />
        </>
      }

      {/* Список спеціальностей */}
      {
        (myData) &&
        <>
          <hr className="mb-4" />
          <SpecialtyList
            data={myData} setMyData={setMyData}
            urlPage="/materials"
            toastifySuccess={popup.toastifySuccess} toastifyWarning={popup.toastifyWarning} />
        </>
      }
    </>
  );
};

export default MaterialsForm;