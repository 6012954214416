import React, { useState, useEffect } from 'react';
import { useLoaderData, useLocation } from 'react-router-dom';
import AxiosInstance from 'src/components/Axios';
import AttributesCreateForm from 'src/components/attributes/AttributesCreateForm';
import Categories from 'src/components/attributes/VariationsCategories';
import Variations from 'src/components/attributes/Variations';
import AttributesCategories from 'src/components/attributes/AttributesCategories';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import MyToastify from 'src/components/myComponents/MyToactify';
import LangugesPanel from 'src/components/attributes/LangugesPanel';
import PermissionsNoPage, {Permissions} from "src/components/Permissions";

const urlApi = '/attributes';

export async function LoaderAttributesUpdate({ params }) {
  if (Object.keys(params).length != 0) {
    let res = await AxiosInstance.get(`${urlApi}/${params.pid}`).then((response) => {
      return response;
    });
    return res;
  }
  return false;
}
const formReducer = (state, event) => {
  return {
    ...state,
    [event.target.name]: event.target.value
  }
}
const AttributesForm = ({user_data}) => {
  if(!Permissions(user_data, 'Attributes', 'EDIT_ATTRIBUTES')){
    return <PermissionsNoPage />;
  }  
  const { data } = useLoaderData();
  const [MyData, setMyData] = useState(data);
  // Викликає popup з власного компонента
  const popup = MyToastify();

  useEffect(() => {
    setMyData(data);
  }, [data]);

  // console.log("MyData", MyData);

  return (
    <>
      {/* Popup про успішні зміни (справа зверху) */}
      <ToastContainer />
      <div className='row'>
        <div className='col'>

        </div>
        <div className='col d-flex justify-content-end'>
          <LangugesPanel data={data}/>
        </div>
      </div>
      

      <AttributesCreateForm data={MyData} toastifySuccess={popup.toastifySuccess} />
      <hr />
      {(MyData != undefined) ? <Variations parent={MyData.id} parent_name={MyData.name} toastifySuccess={popup.toastifySuccess} /> : ''}
      <hr />
      {(MyData != undefined) ? <AttributesCategories attribute_id={MyData.id} toastifySuccess={popup.toastifySuccess} toastifyWarning={popup.toastifyWarning} /> : ''}
    </>
  );
};

export default AttributesForm;