import React, { useState, useEffect } from 'react';
import AsyncSelect from 'react-select/async';
import AxiosInstance from '../Axios';
import { CBadge, CListGroup, CListGroupItem, CButton, CCol, CRow } from '@coreui/react';
import { confirmAlert } from 'react-confirm-alert';
import { Link } from 'react-router-dom';


// Допоміжні спеціальності
const AuxiliarySpecialties = (props) => {
    // console.log('AuxiliarySpecialties props', props);

    const urlApi = '/specialties';
    const urlRouter = '/specialty';

    const [existingIds, setExistingIds] = useState([]); // Масив з доданих спеціальностей, які не потрібно виводити в селекті
    const [specialtiesIRIs, setSpecialtiesIRIs] = useState([]); // Масив з допоміжними спеціальностями в IRI форматі
    const [isShowSelect, setIsShowSelect] = useState(false); // Чи показувати селект для вибору допоміжних спеціальностей
    const [selectedOption, setSelectedOption] = useState(null); // Вибране значення допоміжних спеціальності
    const [isDisabledAddBtn, setIsDisabledAddBtn] = useState(true);
    let i = 0; // Порядковий номер для доданих допоміжних спеціальностей

    const popupTextAddSuccess = "Додаткову спеціальність додано";
    const popupTextDeleteSuccess = "Додаткову спеціальність видалено";
    const popupTextAddWarning = "Додаткову спеціальність не додано";
    const popupTextDeleteWarning = "Додаткову спеціальність не видалено";

    const alertText = 'Допоміжні спеціальності не будуть доступні для вибору, оскільки в полі "Кількість допоміжних спеціальностей" вказано "0"';
    const [isShowAtertText, setIsShowAtertText] = useState(false);

    // відслідковувати чи показувати повідомлення
    useEffect(() => {
        if (props.countSub == 0 && props.data.specialties.length > 0) {
            setIsShowAtertText(true);
        } else {
            setIsShowAtertText(false);
        };
    }, [props.data, props.countSub]);

    // Відслідковувати зміну id для очищення даних
    useEffect(() => {
        setExistingIds([]);
        setSpecialtiesIRIs([]);
        setIsShowSelect(false);
        setSelectedOption(null);
    }, [props.data.id]);

    useEffect(() => {
        if (props?.data) {
            // Заповнити масив з доданих спеціальностей, які не потрібно виводити в селекті
            setExistingIds([
                props.data.id,
                ...props.data.specialties.map(specialty => specialty.id)
            ]);

            // Перетворюємо кожен id у specialties на IRI формат
            const arr = props.data.specialties.map(specialty => `/api/specialties/${specialty.id}`);
            setSpecialtiesIRIs(arr);
        }
    }, [props.data]);

    // Оновити список додаткових спеціальностей для селекту
    useEffect(() => {
        if (existingIds.length > 0) {
            setIsShowSelect(true);
            loadOptions();
        }
    }, [existingIds]);

    // Функція для зміни значення в селекті
    const handleChange = (selectedOption) => {
        setSelectedOption(selectedOption);
    };

    // Функція для завантаження всіх спеціальностей
    const loadOptions = async (inputValue) => {
        // Виконуємо запит для отримання даних
        const response = await AxiosInstance.get(`${urlApi}?name=${inputValue}`, {
            headers: { 'accept': 'application/json' }
        });

        // Фільтруємо дані, виключаючи ті, що вже є у props
        const filteredData = response.data.filter(result => !existingIds.includes(result.id));

        // Повертаємо відфільтровані дані у форматі {label, value}
        return filteredData.map((result) => ({
            label: result.name,
            value: result.id,
        }));
    };

    // Додаємо вибраний option до specialties
    useEffect(() => {
        if (selectedOption) {
            // console.log('selectedOption', selectedOption);
            const specialtiesIRIsArr = props.data.specialties.map(specialty => `/api/specialties/${specialty.id}`);
            specialtiesIRIsArr.push(`/api/specialties/${selectedOption.value}`);
            setSpecialtiesIRIs(specialtiesIRIsArr);
            setIsDisabledAddBtn(false);
        } else {
            setIsDisabledAddBtn(true);
        };
    }, [selectedOption]);

    // Функція для оновлення спеціалності
    const putSpecailties = async (article, popupTextSuccess, popupTextWarning) => {
        try {
            const response = await AxiosInstance.put(`${urlApi}/${props.data.id}`, article);
            // console.log("specialties put response", response);
            props.setMyData(response.data);
            // Popup про успішні зміни
            props.toastifySuccess(popupTextSuccess);
            // Очистити вибраний елемент
            setSelectedOption(null);
            setIsShowSelect(false);
        } catch (error) {
            // Popup про успішні зміни
            props.toastifySuccess(popupTextWarning);
            console.error("Спеціальність не оновлено", error);
        };
    };

    // Функція для додавання додаткової спеціальності
    const handleSave = () => {
        let article = {
            specialties: specialtiesIRIs
        };
        // Оновити додаткові спеціальності в основній спеціальності
        putSpecailties(article, popupTextAddSuccess, popupTextAddWarning);
    };

    // Функція для видалення допоміжної спеціальності
    function Deleted(row) {
        // console.log('row', row);
        const arr = existingIds.filter(id => (id !== row.id) && (id !== props.data.id));
        const specialtiesIRIs = arr.map(id => `/api/specialties/${id}`);
        let article = {
            specialties: specialtiesIRIs
        };

        confirmAlert({
            title: 'Ви впевнені що хочите видалити цей пункт ?',
            message: 'Are you sure to do this.',
            buttons: [
                {
                    label: 'Так',
                    // Оновити додаткові спеціальності в основній спеціальності
                    onClick: () => { putSpecailties(article, popupTextDeleteSuccess, popupTextDeleteWarning) }
                },
                {
                    label: 'Ні',
                    onClick: () => { return }
                }
            ]
        });
    };

    return (
        <>
            <div className="row">
                <div className="col"><h3>Допоміжні спеціальності</h3></div>
                {
                    (existingIds.length > 0 && isShowSelect) &&
                    <div className="col d-flex">
                        <AsyncSelect
                            cacheOptions
                            loadOptions={loadOptions}
                            defaultOptions
                            placeholder="Виберіть спеціальність"
                            value={selectedOption}
                            onChange={handleChange}
                            className="flex-grow-1 me-2"
                            noOptionsMessage={() => "Спеціальності відсутні"}
                        />
                        <CButton className="mb-2" onClick={handleSave} color="secondary" disabled={isDisabledAddBtn}>+</CButton>
                    </div>
                }
            </div>

            {
                isShowAtertText &&
                <div className="ms-1 my-2" style={{ color: "red" }}>{alertText}</div>
            }

            {/* Список з допоміжними спеціальностями */}
            {
                (props.data.specialties.length > 0) ?
                    <CListGroup className="mb-2">
                        {
                            props.data.specialties.map((v, k) => {
                                i++;

                                return (
                                    <CListGroupItem key={k}>
                                        <div className="d-flex justify-content-between align-items-start">
                                            <div>
                                                <span className='me-2'>{`${i})`}</span>
                                                <Link to={`${urlRouter}/update/${v.id}`} className='ms-2'>{v.name}</Link>
                                            </div>
                                            <CButton className='ms-auto' color="danger" variant="outline" onClick={() => { Deleted(v) }}>
                                                Видалити
                                            </CButton>
                                        </div>
                                    </CListGroupItem>
                                )
                            })
                        }
                    </CListGroup >
                    :
                    <p className="ml-3 mb-2">Допоміжні спеціальності відсутні</p>
            }
        </>
    );
};

export default AuxiliarySpecialties;