import { useEffect, useState, useRef } from "react";
import AxiosInstance from "../Axios";
import Col from 'react-bootstrap/Col';
import Nav from 'react-bootstrap/Nav';
import Row from 'react-bootstrap/Row';
import Tab from 'react-bootstrap/Tab';
import { Editor } from '@tinymce/tinymce-react';
import { CForm, CFormLabel, CFormInput, CButton } from '@coreui/react';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import MyToastify from 'src/components/myComponents/MyToactify';



const EventsLocation = ({ event_id, isGetEventLocations, setIsGetEventLocations }) => {
    // const EventsLocation = (props) => {
    const [eventLocation, setEventLocation] = useState([]);
    const apiKey = process.env.REACT_APP_TINYMCE_API_KEY;
    const editorRef = useRef(null);
    const [editorContent, setEditorContent] = useState({});
    const popup = MyToastify();

    const popupTextAddSuccess = "Додані матеріали змінено";
    const popupTextDeleteSuccess = "Додані матеріали не змінено";

    const [activeTab, setActiveTab] = useState((eventLocation.length > 0) ? `tab_${eventLocation[0].id}` : '');

    function getEventLocation() {
        // AxiosInstance.get('/event_locations', { params: { 'event.id': props.event_id } }).then((response) => {
        AxiosInstance.get('/event_locations', { params: { 'event.id': event_id } }).then((response) => {
            setEventLocation(response.data['hydra:member']);
            let editCont = {};
            response.data['hydra:member'].map((e) => {
                editCont[e.id] = e.description;
            });
            setEditorContent(editCont);
            setActiveTab((response.data['hydra:member'].length > 0) ? `tab_${response.data['hydra:member'][0].id}` : '')
            setIsGetEventLocations(false);
        }).catch((e) => {
            console.log(e);
        })
    }


    useEffect(() => {
        if (isGetEventLocations) {
            getEventLocation();
        };
        // }, [])
    }, [isGetEventLocations])

    const handleSubmit = (id) => {

        console.log(id);
        let data_response = {};
        data_response['description'] = editorContent[id];
        // const response = AxiosInstance.put(`/event_locations/${props.event_id}`, data_response).then((response) => {
        const response = AxiosInstance.put(`/event_locations/${id}`, data_response).then((response) => {
            if (response.status === 200) {
                popup.toastifySuccess('Дані оновлено');
                getEventLocation();
                // return navigate(urlRouter);
            } else {
                popup.toastifyWarning('Дані не оновлено');
            }
        });
    }
    const EditorChange = (content, editor) => {
        // console.log(content);
        let newContent = editorContent;
        newContent[editor] = content;
        setEditorContent(newContent);
        // console.log(newContent);
    }

    // useEffect(() => {
    //     console.log(activeTab);
    // }, [activeTab])


    return (
        <>
            {/* <ToastContainer /> */}
            <h3>Опис графіку  навчань</h3>
            {(activeTab.length > 0) &&
                <Tab.Container id="left-tabs-example" defaultActiveKey={activeTab}>
                    <Row>
                        <Col sm={3}>
                            <Nav variant="pills" className="flex-column">
                                {eventLocation.map((e) => {
                                    return (
                                        // <>
                                        <Nav.Item key={`tab-${e.id}`}>
                                            <Nav.Link eventKey={`tab_${e.id}`}>{e.location.name}</Nav.Link>
                                        </Nav.Item>
                                        // </>
                                    );
                                })}
                            </Nav>
                        </Col>
                        <Col sm={9}>
                            <Tab.Content>
                                {eventLocation.map((e) => {
                                    return (
                                        // <>
                                        <Tab.Pane eventKey={`tab_${e.id}`} key={`cont-${e.id}`}>
                                            <CForm className="mb-2">
                                                <h5>{e.location.name}</h5>

                                                <Editor
                                                    apiKey={apiKey}
                                                    onInit={(evt, editor) => editorRef.current = editor}
                                                    initialValue={e.description}
                                                    onEditorChange={(content, editor) => EditorChange(content, e.id)}
                                                    init={{
                                                        height: 600,
                                                        menubar: 'edit view insert format',
                                                        plugins: [
                                                            'advlist', 'autolink',
                                                            'lists', 'link', 'image', 'charmap', 'preview', 'anchor', 'searchreplace', 'visualblocks', 'fullscreen',
                                                            'insertdatetime', 'media', 'table', 'help', 'code'
                                                        ],
                                                        toolbar: 'undo redo | fontfamily fontsize | fontselect | bold italic backcolor | ' +
                                                            'alignleft aligncenter alignright alignjustify | ' +
                                                            'bullist numlist checklist outdent indent | removeformat | link media a11ycheck code table help',
                                                        content_style: 'body { font-family:FixelDisplayRegular; font-size:14px }',
                                                        font_family_formats: "Andale Mono=andale mono,times; Arial=arial,helvetica,sans-serif; Arial Black=arial black,avant garde; Book Antiqua=book antiqua,palatino; Comic Sans MS=comic sans ms,sans-serif; Courier New=courier new,courier; Georgia=georgia,palatino; Helvetica=helvetica; Impact=impact,chicago; Fixel Display Regular=fixelDisplayRegular; Symbol=symbol; Tahoma=tahoma,arial,helvetica,sans-serif; Terminal=terminal,monaco; Times New Roman=times new roman,times; Trebuchet MS=trebuchet ms,geneva; Verdana=verdana,geneva; Webdings=webdings; Wingdings=wingdings,zapf dingbats",
                                                    }}
                                                />
                                            </CForm>
                                            <CButton type="submit" className="mb-2" color="success" onClick={() => { handleSubmit(e.id) }}>Зберегти</CButton>
                                        </Tab.Pane>
                                        // </>
                                    );
                                })}
                            </Tab.Content>
                        </Col>
                    </Row>
                </Tab.Container>
            }

        </>
    )
}

export default EventsLocation;