/* eslint-disable react/react-in-jsx-scope */
/* eslint-disable react/prop-types */
/* eslint-disable prettier/prettier */
import { useEffect, useState } from "react";
import AxiosInstance from "../Axios";
import { confirmAlert } from 'react-confirm-alert';
import { CBadge, CListGroup, CListGroupItem, CButton, CIcon } from '@coreui/react';
import EditProductInfo from 'src/components/product/EditProductInfo';
import BasicModal from 'src/components/Modal';
import { sortObj } from "../store/sortObj";

const urlApi = '/product_infos';

const ViewProductInfo = ({ product, update, setUpdate, popup }) => {

    const [showModal, setShowModal] = useState(false);
    const [currentProduct, setCurrentProduct] = useState(null);
    const [productInfo, setProductInfo] = useState(false);

    // Текст для popup
    const popupTextSuccess = "Інформація про товар видалена";

    const actionShowModal = (rez, isUpdate = false) => {
        setShowModal(rez);
        if (!rez && isUpdate) {
            setUpdate(true);
            getProductInfo();
        }
    };

    const getProductInfo = () => {
        AxiosInstance.get(`${urlApi}?product.id=${product}`).then((response) => {
            if (response.status == 200) {
                setUpdate(false);
                return setProductInfo(response.data['hydra:member']);
            }
        });
    };

    useEffect(() => {
        if (productInfo === false || update)
            getProductInfo();
    }, [update]);

    function Deleted(row) {
        confirmAlert({
            title: 'Ви впевнені що хочите видалити цей пункт ?',
            message: 'Are you sure to do this.',
            buttons: [
                {
                    label: 'Так',
                    onClick: () => {
                        const response = AxiosInstance.delete('/product_infos/' + row.id).then((response) => {
                            // Popup про успішні зміни
                            popup.toastifySuccess(popupTextSuccess);
                            getProductInfo();
                        });
                    }
                },
                {
                    label: 'Ні',
                    onClick: () => {
                        return;
                    }
                }
            ]
        });
    };

    return (
        <div className="mb-3">
            {
                (productInfo !== false && productInfo.length > 0) ?
                    <>
                        <CListGroup>
                            {
                                sortObj(productInfo, 'sort').map((v, k) => {
                                    return (
                                        <CListGroupItem key={k} className="d-flex justify-content-between align-items-center">
                                            <p>{v.sort}) <strong>{v.name}</strong> - {v.value}</p>
                                            <div className="edit-inner">
                                                <CButton color="secondary" onClick={() => {
                                                    setCurrentProduct(v);
                                                    setShowModal(true);
                                                }} >
                                                    Редагувати
                                                </CButton>
                                                <CButton color="danger" variant="outline" onClick={() => { Deleted(v) }} >
                                                    Видалити
                                                </CButton>
                                            </div>
                                        </CListGroupItem>
                                    )
                                })
                            }
                        </CListGroup>

                        {
                            currentProduct &&
                            <div style={{ display: "none" }}>
                                <BasicModal
                                    btn_name={'Редагувати'}
                                    show={showModal}
                                    actionShowModal={actionShowModal}
                                    title={`Редагувати інформацію`}
                                    content={
                                        <EditProductInfo
                                            actionShowModal={actionShowModal}
                                            product={currentProduct}
                                            toastifySuccess={popup.toastifySuccess}
                                            toastifyWarning={popup.toastifyWarning}
                                        />
                                    } />
                            </div>
                        }
                    </>
                    :
                    <p className="ml-3">інформації не знайдено</p>
            }
        </div>
    )
};

export default ViewProductInfo;