import React, { useEffect, useRef, useState } from 'react';
import { Editor } from '@tinymce/tinymce-react';
import {
  CForm,
  CFormLabel,
  CFormInput,
  CButton,
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CRow,
  CFormSelect,
  CFormTextarea

} from '@coreui/react';
import { Link, useLoaderData, useNavigate } from 'react-router-dom';
import axios from "axios";
import { formatUTC } from 'src/store';
import dayjs from 'dayjs';
import AddHeaderSlider from 'src/components/headers/AddHeaderSlider';
import AxiosInstance from 'src/components/Axios';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import MyToastify from 'src/components/myComponents/MyToactify';
import PageInfoList from 'src/components/page-info/PageInfoList';
import Tab from 'react-bootstrap/Tab';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Nav from 'react-bootstrap/Nav';

import FormAttributes from 'src/components/attributes/FormAttributes';
import AddCategories from 'src/components/category/AddCategories';
import PermissionsNoPage, {Permissions} from "src/components/Permissions";

const tokenStr = localStorage.getItem('user');
const header = { headers: { "Authorization": `Bearer ${tokenStr}` } };

export async function LoaderObjectsUpdate({ params }) {

  if (Object.keys(params).length != 0) {
    let res = await AxiosInstance.get('/objects/' + params.id).then((response) => {
      return response;
    });
    return res;
  }
  return false;
}
const formReducer = (state, event) => {
  return {
    ...state,
    [event.target.name]: event.target.value
  }
}
const ObjectsForm = ({user_data}) => {
  if(!Permissions(user_data, 'Objects', 'EDIT_OBJECTS')){
      return <PermissionsNoPage />;
  }
  const { data } = useLoaderData();
  const [name, setName] = useState((data != undefined) ? data.name : '');
  const [status, setStatus] = useState((data != undefined) ? data.status : '');
  const [sliders, setSliders] = useState((data != undefined && data.slider != undefined)? data.slider.id: false);
  const popup = MyToastify();

  const editorRef = useRef(null);
  const navigate = useNavigate();
  // Ключ для Editor
  const apiKey = process.env.REACT_APP_TINYMCE_API_KEY;

  const handleSubmit = event => {
    event.preventDefault();
    if (editorRef.current) {

      const article = {
        name: name,
        // dateStart: dateStart,
        // dateEnd: dateEnd,
        status: status,
        content: editorRef.current.getContent(),
      };
      // console.log(article);
      if (data != undefined) {
        const response = axios.put(process.env.REACT_APP_SERVER_URL + '/api/objects/' + data.id, article, header).then(()=>{navigate('/objects');});
      } else {
        const response = axios.post(process.env.REACT_APP_SERVER_URL + '/api/objects', article, header).then(()=>{navigate('/objects');});
      }
    }
  }

  // useEffect(() => {
  //   if(sliders != undefined){
  //     const response = AxiosInstance.put(`projects/${data.id}`, {slider:`/api/sliders/${sliders}`}).then(()=>{
  //       popup.toastifySuccess('Слайдер додано');
  //     });
  //   }
  // }, [sliders]);

  return (
    <>
      <ToastContainer />
      <h2>{name}</h2>
      <hr/>
      <Tab.Container id="left-tabs-example" defaultActiveKey="img">
        <Row>
          <Col sm={2}>
            <Nav variant="pills" className="flex-column">
              <Nav.Item>
                <Nav.Link eventKey="info">Загальні</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="img">Зображення</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="attributes">Атрибути</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="category">Категорії</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="data">Додаткові дані</Nav.Link>
              </Nav.Item>
            </Nav>
          </Col>
          <Col sm={10}>
            <Tab.Content>
              <Tab.Pane eventKey="info">
                <CForm onSubmit={handleSubmit}>
                    <div className="mb-3">
                      <CFormLabel htmlFor="exampleFormControlInput1">Назва</CFormLabel>
                      <CFormInput type="text" id="exampleFormControlInput1" value={name} onChange={(e) => setName(e.target.value)} autoComplete="off" />
                    </div>
                    <div className="row">
                      <div className="col">
                        <CFormLabel htmlFor="exampleFormControlInput1">Довгота</CFormLabel>
                        <CFormInput type="text" id="exampleFormControlInput1" value={name} onChange={(e) => setName(e.target.value)} autoComplete="off" />
                      </div>
                      <div className="col">
                        <CFormLabel htmlFor="exampleFormControlInput1">Широта</CFormLabel>
                        <CFormInput type="text" id="exampleFormControlInput1" value={name} onChange={(e) => setName(e.target.value)} autoComplete="off" />
                      </div>

                    </div>
                    <div className="mb-3">
                      <CFormLabel htmlFor="exampleFormControlInput1">Модель</CFormLabel>
                      <CFormTextarea type="text" id="exampleFormControlInput1" value={name} onChange={(e) => setName(e.target.value)} autoComplete="off" />
                    </div>
                    <div className="mb-3">
                      <CFormLabel htmlFor="exampleFormControlInput1">Тип моделі</CFormLabel>
                      <CFormSelect
                        aria-label="Default select example"
                        onChange={(e) => setStatus(e.target.value)}
                        defaultValue={status}
                        required
                        options={[
                          { label: 'sketchfab', value: 'Sketchfab' },
                          { label: 'nira', value: 'Nira' },
                          { label: 'other', value: 'інше' }
                        ]}
                      />
                    </div>
                    <div className="mb-3">
                      <CFormLabel htmlFor="exampleFormControlInput1">Статус</CFormLabel>
                      <CFormSelect
                        aria-label="Default select example"
                        onChange={(e) => setStatus(e.target.value)}
                        defaultValue={status}
                        required
                        options={[
                          // { label: 'Статус проекту', value: '' },
                          { label: 'Completed', value: 'Completed' },
                          { label: 'Planned', value: 'Planned' },
                          { label: 'Pending', value: 'Pending' }
                        ]}
                      />
                    </div>
                    <div className="mb-3">
                      <Editor
                        apiKey={apiKey}
                        onInit={(evt, editor) => editorRef.current = editor}
                        initialValue={(data != undefined) ? data.content : ''}
                        init={{
                          height: 300,
                          menubar: 'edit view insert format',
                          plugins: [
                            'advlist','autolink',
                            'lists','link','image','charmap','preview','anchor','searchreplace','visualblocks','fullscreen',
                            'insertdatetime','media','table', 'help', 'code'
                         ],
                          toolbar: 'undo redo | fontfamily fontsize | fontselect | bold italic backcolor | ' +
                            'alignleft aligncenter alignright alignjustify | ' +
                            'bullist numlist checklist outdent indent | removeformat | link media a11ycheck code table help',
                          content_style: 'body { font-family:FixelDisplayRegular; font-size:14px }',
                          font_family_formats: "Andale Mono=andale mono,times; Arial=arial,helvetica,sans-serif; Arial Black=arial black,avant garde; Book Antiqua=book antiqua,palatino; Comic Sans MS=comic sans ms,sans-serif; Courier New=courier new,courier; Georgia=georgia,palatino; Helvetica=helvetica; Impact=impact,chicago; Fixel Display Regular=fixelDisplayRegular; Symbol=symbol; Tahoma=tahoma,arial,helvetica,sans-serif; Terminal=terminal,monaco; Times New Roman=times new roman,times; Trebuchet MS=trebuchet ms,geneva; Verdana=verdana,geneva; Webdings=webdings; Wingdings=wingdings,zapf dingbats",
                        }}
                      />
                    </div>
                    <CButton type="submit" color="success">Зберегти</CButton>
                </CForm>
              </Tab.Pane>
              <Tab.Pane eventKey="img">
                111
              </Tab.Pane>
            <Tab.Pane eventKey="attributes">
              <FormAttributes dataId={data.id} attributeItems={data.attributeItems} toastifySuccess={popup.toastifySuccess} type="products" url="/objects" />
            </Tab.Pane>
            <Tab.Pane eventKey="category">
              {(data != undefined) ? <AddCategories type="objects" data_id={data.id} toastifySuccess={popup.toastifySuccess} /> : ''} 
            </Tab.Pane>
            <Tab.Pane eventKey="data">
              {(data != undefined)&&
                <PageInfoList id={data.id} type="project" />
              }
            </Tab.Pane>
            </Tab.Content>
          </Col>
        </Row>
      </Tab.Container>

        {/* <AddHeaderSlider id={(sliders !== false) ? sliders : undefined} newSlider={setSliders} parentUrl={`objects/${data.id}`}/>   */}
       
      

      {/* {(data != undefined)&&
        <PageInfoList id={data.id} type="project" />
      } */}
    </>
  );
};

export default ObjectsForm;
