import React, { Component, Suspense } from 'react'
import { HashRouter, Route, Routes } from 'react-router-dom'
import './scss/style.scss'
import {Navigate, RouterProvider, createBrowserRouter} from "react-router-dom";


const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)

// Containers
const DefaultLayout = React.lazy(() => import('./layout/DefaultLayout'))

// Pages
const Login = React.lazy(() => import('./views/pages/login/Login'))
// const Register = React.lazy(() => import('./views/pages/register/Register'))
const Page404 = React.lazy(() => import('./views/pages/page404/Page404'))
// const Page500 = React.lazy(() => import('./views/pages/page500/Page500'))

// routes config
import routes from './routes';

const router = createBrowserRouter([
  {
    path: "/",
    element: <DefaultLayout />,
    errorElement: <Page404 />,
    children: routes,
  },
]);

class App extends Component {
  render() {
    return (
        <Suspense fallback={loading}>
          {/* <React.StrictMode> */}
            <RouterProvider router={router} />
          {/* </React.StrictMode> */}
        </Suspense>
    )
  }
}

export default App
