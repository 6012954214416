import React, { useEffect, useRef, useState } from 'react';
import { Editor } from '@tinymce/tinymce-react';
import { Link, useLoaderData, useLocation } from 'react-router-dom';
import AxiosInstance from 'src/components/Axios';
import { useNavigate } from "react-router-dom";
import { CForm, CFormLabel, CFormInput, CButton } from '@coreui/react';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import MyToastify from 'src/components/myComponents/MyToactify';
import AuxiliarySpecialties from 'src/components/specialty/AuxiliarySpecialties';


const urlApi = '/specialties';
const urlRouter = '/specialty';

export async function LoaderSpecialtyUpdate({ params }) {
  if (Object.keys(params).length != 0) {
    let res = await AxiosInstance.get(`${urlApi}/${params.pid}`).then((response) => {
      return response;
    });
    return res;
  }
  return false;
};

// Форма для спеціальності (напрямку)
const SpecialtyForm = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { data } = useLoaderData();
  const [myData, setMyData] = useState((data != undefined) ? data : false);
  const [name, setName] = useState(myData ? myData.name : '');
  const [code, setCode] = useState(myData ? myData.code : '');
  const [countSub, setCountSub] = useState(myData?.countSub ? myData?.countSub : 0);
  // const editorRef = useRef(null);
  const isInitialRender = useRef(true); // Для відслідковування чи перший рендер

  useEffect(() => {
    setMyData(data);
  }, [data]);

  // Викликає popup з власного компонента
  const popup = MyToastify();
  // Текст для popup
  const popupTextPutSuccess = "Спеціальність оновлено";
  const popupTextPostSuccess = "Спеціальність створено";
  const popupTextPutWarning = "Спеціальність не оновлено";
  const popupTextPostWarning = "Спеціальність не створено";
  const popupTextGetWarning = "Помилка запиту за спеціальністю";

  // Відслідковувати зміну id для запиту за даними
  useEffect(() => {
    // Перевіряємо, чи це перший рендер
    if (isInitialRender.current) {
      isInitialRender.current = false; // Після першого рендеру ставимо прапорець в false
      return;
    };

    getData();
  }, [location]);

  // Функція для запиту за даними
  const getData = async () => {
    try {
      const response = await AxiosInstance.get(`${urlApi}/${data.id}`);
      // console.log('specialties get response', response);
      setMyData(response.data);
    } catch (error) {
      popup.toastifyWarning(popupTextGetWarning);
      console.error("Помилка запиту за спеціальністю", error);
    };
  };

  // Заповнити дані
  useEffect(() => {
    if (myData) {
      setName(myData ? myData.name : '');
      setCode(myData ? myData.code : '');
      setCountSub(myData?.countSub ? myData.countSub : 0);
    };
  }, [myData]);

  const handleSubmit = event => {
    event.preventDefault();

    const data_response = {
      name: name,
      code: code,
      countSub: parseFloat(countSub),
    };
    if (data != undefined) {
      const response = AxiosInstance.put(`${urlApi}/${data.id}`, data_response).then((response) => {
        if (response.status === 200) {
          popup.toastifySuccess(popupTextPutSuccess);
          setMyData(response.data);
          // return navigate(urlRouter);
        } else {
          popup.toastifyWarning(popupTextPutWarning);
        }
      });
    } else {
      const response = AxiosInstance.post(`${urlApi}`, data_response).then((response) => {
        if (response.status === 201) {
          // return navigate(urlRouter);
          // Popup про успішні зміни
          popup.toastifySuccess(popupTextPostSuccess);
          // alert("Дані оновлено");
          navigate(`${urlRouter}/update/${response.data.id}`);
        } else {
          popup.toastifyWarning(popupTextPostWarning);
        }
      });
    };
  };

  // Функція для перевірки "Кількість допоміжних спеціальностей" на заповнення
  const checkCountSub = () => {
    if (countSub === "") {
      setCountSub(myData?.countSub ? myData.countSub : 0);
    };
  };

  return (
    <>
      {/* Popup про успішні зміни (справа зверху) */}
      <ToastContainer />

      <CForm onSubmit={handleSubmit} className="mb-3">
        <div className="mb-3">
          <CFormLabel htmlFor="exampleFormControlInput1">Назва</CFormLabel>
          <CFormInput type="text" id="exampleFormControlInput1" value={name} onChange={(e) => setName(e.target.value)} autoComplete="off" required />
        </div>
        <div className='mb-3 row'>
          <div className='col'>
            <CFormLabel htmlFor="exampleFormControlInput1">Код</CFormLabel>
            <CFormInput type="text" id="exampleFormControlInput1" value={code} onChange={(e) => setCode(e.target.value)} autoComplete="off" required />
          </div>
          <div className='col'>
            <CFormLabel htmlFor="exampleFormControlInput1">Кількість допоміжних спеціальностей</CFormLabel>
            <CFormInput
              type="number" id="exampleFormControlInput1" value={countSub}
              onChange={(e) => setCountSub(e.target.value)} onBlur={() => checkCountSub()}
              autoComplete="off" required
            />
          </div>
        </div>
        <CButton type="submit" color="success">Зберегти</CButton>
      </CForm>


      {/* Допоміжні спеціальності */}
      {
        (myData) &&
        <>
          <hr className="mb-4" />
          <AuxiliarySpecialties data={myData} setMyData={setMyData} countSub={countSub} toastifySuccess={popup.toastifySuccess} toastifyWarning={popup.toastifyWarning} />
        </>
      }
    </>
  );
};

export default SpecialtyForm;