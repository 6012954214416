import React, { useEffect, useState } from 'react';
import { Link, useLoaderData } from 'react-router-dom';
import AxiosInstance from 'src/components/Axios';
import { CCard, CCardBody, CCardHeader, CCol, CRow, CButton , CFormTextarea, CForm, CFormInput} from '@coreui/react';
import { cilPen, cilPlus, cilTrash, cilFile } from '@coreui/icons';
import CIcon from '@coreui/icons-react';
import { Button, Col, FormControl, FormSelect, Row } from 'react-bootstrap';
import MyToastify from 'src/components/myComponents/MyToactify';
import { ToastContainer } from 'react-toastify';
import PermissionsNoPage, {Permissions} from "src/components/Permissions";
import { CFormSelect } from "@coreui/react";
import { faL } from '@fortawesome/free-solid-svg-icons';

const urlApi = '/form_answers';
const url = process.env.REACT_APP_SERVER_URL;

export async function LoaderFormAnswerItem({ params }) {
    if (Object.keys(params).length != 0) {
        let res = await AxiosInstance.get(`${urlApi}/${params.pid}`).then((response) => {
            return response;
        });
        return res;
    }
    return false;
};


// Компонент заповненої форм
const FormAnswerItem = ({user_data}) => {
    if(!Permissions(user_data, 'FormAnswer', 'VIEW_FORMANSWER')){
        return <PermissionsNoPage />;
    }
    const { data } = useLoaderData();
    // console.log('data', data);

    // Додані взяті з API
    const [dataFormAnswer, setDataFormAnswer] = useState(false);
    const [dataAnswerFields, setDataAnswerFields] = useState([]);
    const [comment, setComent] = useState(data.description);
    const [orderStatus, setOrderStatus] = useState(data.status);
    const [media, setMedia] = useState();
    const [disableBtnMail, setDisableBtnMail] = useState(false);
    const [emails, setEmails] = useState((data.data != undefined && data.data != null)? data.data: []);
    const [emailData, setEmailData] = useState({
        email: '',
        title: '',
        content: ''
    });
    let commentID;
    let statusID;
    const popup = MyToastify();


    // Взяти необідні дані, які прийшли з сервера
    useEffect(() => {
        if (data !== undefined) {
            setDataFormAnswer(data.form);
            setDataAnswerFields(data.formAnswerFields);
        };
        if (data.media.length > 0) {
            setMedia(data.media);
        };

        if(data.status == 'new' || data.status == '' || data.status == null){
            AxiosInstance.put(`/form_answers/${data.id}`, { status: 'opened' }).then((res) => {
                if(res.data.status !=undefined ){
                    popup.toastifySuccess("Статус змінено на Відкрито!");
                    setOrderStatus( res.data.status )
                }

                // setDisableBtnMail((res.data.data !=undefined && res.data.data.send)? true: false)
            });
        }
    }, [data]);

    // useEffect(() => {
    //     console.log('media', media);
    // }, [media]);

    useEffect(() => {
        console.log('setOrderStatus', orderStatus);
    }, [orderStatus]);

    useEffect(() => {
        let mails = dataAnswerFields.filter((row) => row.name == 'mail' || row.name == 'email' );
        if(mails.length > 0)
            handleChange(mails[0].value, 'email');
    }, [dataAnswerFields]);

    const handleSubmit  = event => {
        event.preventDefault();
        let d = emails;
        d.push(emailData);


        AxiosInstance.put(`/form_answers/${data.id}`, { data: d }).then((res) => {
            // if(res.data.data !=undefined && res.data.data.send)
                popup.toastifySuccess("Email відправлено!");
                setEmails(res.data.data);
                setOrderStatus( res.data.status )
            // setDisableBtnMail((res.data.data !=undefined && res.data.data.send)? true: false)
        });
    }

    
    const addStatus = () => {
        AxiosInstance.put(`/form_answers/${data.id}`, { status: orderStatus }).then(() => {
            AxiosInstance.get(`${urlApi}/${data.id}`).then((res) => {
                // console.log('res', res.data);
                if (res.status == 200) {
                    popup.toastifySuccess("Статус успішно змінено");
                };
                setDataAnswerFields(res.data.formAnswerFields);
            });
        });
    };

    const addComment = () => {
        AxiosInstance.put(`/form_answers/${data.id}`, { description: comment }).then((resp) => {
            if (resp.status == 200) {
                popup.toastifySuccess("Коментар успішно додано");
            };
        }).then(() => {
            AxiosInstance.get(`${urlApi}/${data.id}`).then((res) => {
                // console.log('res', res.data);
                setDataAnswerFields(res.data.formAnswerFields);
            });
        });
    };
    const handleChange = (value, name) => {
        setEmailData({
            ...emailData,
            [name]: value
        });
    };

    return (
        <>
            <ToastContainer />
            <div className='row'>
                <div className='col mb-4' style={{ padding: "15px", border: "1px solid #d8dbe0", backgroundColor: "rgba(255, 255, 255, 0.87)", borderRadius: "7px" }}>
                    <div className="mb-4">
                        <Row>
                            <Col>
                                <h2>{dataFormAnswer.name}</h2>
                                <p>{dataFormAnswer.description}</p>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <h4>Дата заповнення</h4>
                                <p>{data.dateEntered.replace('T', ' ').slice(0, -6)}</p>
                            </Col>
                        </Row>
                    </div>
                    <hr />
                    {/* Заповнена форма */}

                    {
                        (Array.isArray(dataAnswerFields) && dataAnswerFields.length > 0) &&
                        dataAnswerFields
                            .sort((a, b) => a.id - b.id) // Сортуємо за id
                            .map((field) => {
                                // console.log('field', field)
                                if (field.name === 'status') {
                                    statusID = field.id;
                                }
                                if (field.name === 'comment') {
                                    commentID = field.id;
                                }
                                // console.log('field', field);
                                const value = (field.value !== "") ? field.value : "Не заповнено";
                                return (
                                    <div key={field.id} className="mb-3">
                                        <h4>{field.formField.label}</h4>
                                        <p>{value}</p>
                                    </div>
                                );
                            })
                    }


                    {/* Медіа */}
                    {
                        (media !== undefined && media.length > 0) &&
                        <div className='mb-3'>
                            <h4 className='mb-3 d-flex'>
                                <span>Прикріплений файл</span>
                                <Link className='ms-3' target='_blank' to={`${url}${media[0].contentUrl}`} preventScrollReset={true}>
                                    <CButton color="dark" variant="outline" className='d-flex align-items-center'>
                                        <CIcon icon={cilFile} customClassName="nav-icon" height={20} />
                                    </CButton>
                                </Link>
                            </h4>
                        </div>
                    }
                </div>
                <div className='col'>
              {/* Коментар менеджера  */}
                <div className='mb-4' style={{ padding: "15px", border: "1px solid #d8dbe0", backgroundColor: "rgba(255, 255, 255, 0.87)", borderRadius: "7px" }}>
                    <div className='mb-3'>
                        <h4 className='mb-3 d-flex'>
                            <span>Коментар менеджера</span>
                        </h4>
                        <CFormTextarea placeholder='Введіть коментар' onChange={(e) => setComent(e.target.value)} value={comment}/>

                        <Button color='primiry' className='mt-3' onClick={addComment}>Зберегти</Button>
                    </div>
                </div> 

                {/* Статус  */}
                <div className='mb-4' style={{ padding: "15px", border: "1px solid #d8dbe0", backgroundColor: "rgba(255, 255, 255, 0.87)", borderRadius: "7px" }}>
                    <h4 className='mt-3 d-flex'>
                        <span>Змінити статус</span>
                    </h4>
                    <CFormSelect
                                aria-label="Default select example"
                                onChange={(e) => setOrderStatus(e.target.value.value)}
                                value={orderStatus}
                                options={[
                                    {value: 'new', label: 'Нове'},
                                    {value: 'opened', label: 'Відкрите'},
                                    {value: 'in_work', label: 'В роботі'},
                                    {value: 'send_answer', label: 'Надіслано відповідь'},
                                ]}
                    />
                    <Button color='primiry' className='mt-3' onClick={addStatus}>Зберегти</Button>
                </div>

            {/* <ToastContainer /> */}
                </div>
            </div>
            <div className='mb-4' style={{ padding: "15px", border: "1px solid #d8dbe0", backgroundColor: "rgba(255, 255, 255, 0.87)", borderRadius: "7px" }}>
                {(emails.length > 0)&&
                    <table className='table'>
                        <thead>
                            <tr>
                                <th>Email</th>
                                <th>Тема</th>
                                <th>Повідомлення</th>
                                <th>Надіслано</th>
                            </tr>
                        </thead>
                        <tbody>
                            {emails.map((row) => (
                                <tr>
                                    <td>{row.email}</td>
                                    <td>{row.title}</td>
                                    <td>{row.content}</td>
                                    <td>{(row.send)? '✅': '❌'}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                
                }
                    <h4 className='mt-3 d-flex'>
                        <span>Надіслати відповідь на Email</span>
                    </h4>
                    <CForm onSubmit={handleSubmit}>
                        <CFormInput
                                id="exampleFormControlInput1"
                                label="Email"
                                placeholder="Email"
                                value={emailData.email}
                                onChange={(e) => { handleChange(e.target.value, 'email') }} 
                                required
                            // value="test"
                            // text="Must be 8-20 characters long."
                            // aria-describedby="exampleFormControlInputHelpInline"
                        />
                        <CFormInput
                                id="exampleFormControlInput2"
                                label="Тема"
                                placeholder=""
                                value={emailData.title}
                                onChange={(e) => { handleChange(e.target.value, 'title') }} 
                                required
                            // value="test"
                            // text="Must be 8-20 characters long."
                            // aria-describedby="exampleFormControlInputHelpInline"
                        />
                        <CFormTextarea 
                            label='Відповідь' 
                            placeholder='Відповідь' 
                            onChange={(e) => handleChange(e.target.value, 'content')} value={emailData.content}
                            required
                        />
                        <CButton  type="submit" color="success" disabled={disableBtnMail}>Відправити</CButton>
                    </CForm>
            </div>  
        </>
    );
};

export default FormAnswerItem;