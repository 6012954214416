import React, { useEffect, useState } from 'react';
import { CFormCheck, CButton } from '@coreui/react';
import AxiosInstance from '../Axios';

// Список підрозділів
const EventsLocationsList = (props) => {
    // console.log('EventsLocationsList props', props);

    const urlApi_1 = '/locations';
    const urlApi_2 = '/events';
    const urlApi_3 = '/event_locations';

    const [locations, setLocations] = useState([]); // Список підрозділів
    const [checkedLocations, setCheckedLocations] = useState([]); // Список підрозділів з checked

    const popupTextAddSuccess = "Додані підроздали змінено";
    // const popupTextDeleteSuccess = "Додані підроздали не змінено";
    const popupTextWarning = "Додані не оновлено";
    const popupTextSuccess = "Додані оновлено";


    // Запит за підрозділами
    const getLocations = async () => {
        try {
            const response = await AxiosInstance.get(urlApi_1);
            // console.log('response', response);
            setLocations(response.data["hydra:member"]);
        } catch (error) {
            console.error("Помилка запиту за підрозділами", error);
        };
    };


    // Функція для оновлення підрозділів
    const putSpecailties = async () => {
        let article = {
            location: checkedLocations
        };
        try {
            const response = await AxiosInstance.put(`${urlApi_2}/${props.data.id}`, article);
            // console.log("specialties put response", response);
            // props.setMyData(response.data);
            // Popup про успішні зміни
            // props.toastifySuccess(popupTextAddSuccess);


            // Список IRI для всіх eventLocations
            const eventLocationIRIs = props.data.eventLocations.map(el => `/api/locations/${el.location.id}`);
            // console.log('eventLocationIRIs', eventLocationIRIs);
            // console.log('checkedLocations', checkedLocations);

            for (const locationIRI of checkedLocations) {
                if (!eventLocationIRIs.includes(locationIRI)) {
                    // Якщо підрозділ відмічений, але його немає у eventLocations, створюємо новий event_location
                    const locationId = locationIRI.split('/').pop(); // Витягуємо ID з IRI
                    // console.log('createEventLocation(locationId)', locationId);
                    await createEventLocation(locationId);
                }
            }

            for (const eventLocationIRI of eventLocationIRIs) {
                if (!checkedLocations.includes(eventLocationIRI)) {
                    // Якщо підрозділ не відмічений, але він є у eventLocations, видаляємо event_location
                    const eventLocation = props.data.eventLocations.find(el => `/api/locations/${el.location.id}` === eventLocationIRI);
                    // console.log('deleteEventLocation(eventLocation.id)', eventLocation.id);
                    await deleteEventLocation(eventLocation.id);
                }
            }

            // Виведення заключного повідомлення
            // alert("Все готово!");
            props.toastifySuccess(popupTextSuccess);
            // Оновити дані
            props.getMaterial();

        } catch (error) {
            // Popup про успішні зміни
            props.toastifyWarning(popupTextWarning);
            console.error("Додані підроздали не змінено", error);
        };
    };

    // Зробити запит за всіма підрозділами
    useEffect(() => {
        getLocations();
    }, []);

    // Відслідковувати для яких підрозділів доданий матеріал у форматі IRI
    useEffect(() => {
        const initialCheckedLocations = props.data.location.map(loc => `/api/locations/${loc.id}`);
        // console.log('initialCheckedLocations', initialCheckedLocations);
        setCheckedLocations(initialCheckedLocations);
    }, [props.data.location]);

    // Обробка зміни стану чекбоксу
    const handleCheckboxChange = (id) => {
        setCheckedLocations((prevState) => {
            const locationIRI = `/api/locations/${id}`;
            if (prevState.includes(locationIRI)) {
                // Видалити з масиву, якщо було відмічено
                return prevState.filter(loc => loc !== locationIRI);
            } else {
                return [...prevState, locationIRI];
            };
        });
    };

    // Функція для створення EventLocations
    const createEventLocation = async (id) => {
        let article = {
            "event": `/api/events/${props.data.id}`,
            "location": `/api/locations/${id}`,
            "description": "",
        };

        try {
            const response = await AxiosInstance.post(urlApi_3, article);
        } catch (error) {
            console.error("Помилка створення event_locations", error);
        };
    };

    // Функція для видалення EventLocations
    const deleteEventLocation = async (id) => {
        try {
            const response = await AxiosInstance.delete(`${urlApi_3}/${id}`);
        } catch (error) {
            console.error("Помилка видалення event_locations", error);
        };
    };

    return (
        <div>
            <h3 className="mb-2">Підрозділи</h3>

            {
                locations.length > 0 &&
                <>
                    {
                        locations.map((location) => {
                            // console.log('location', location);
                            const isChecked = checkedLocations.includes(`/api/locations/${location.id}`);

                            return (
                                <CFormCheck className="mb-2"
                                    id={`location-${location.id}`}
                                    label={location.name} key={location.id}
                                    checked={isChecked}
                                    onChange={() => handleCheckboxChange(location.id)}
                                />
                            );
                        })
                    }
                    <div className="mb-2">
                        <CButton color="success" onClick={() => putSpecailties()}>Зберегти</CButton>
                    </div>
                </>
            }
        </div >
    );
};

export default EventsLocationsList;