
import { useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import Card from 'react-bootstrap/Card';



const MyPopovers = ({ product, reduxBalance, measurement_unit }) => {
    return (
        <div className="">
            <p className="h6">Ваш резерв на складі:</p>
            {
                (reduxBalance != undefined && reduxBalance.length > 0) ?
                    <ul>
                        {
                            reduxBalance.map((val, index) => {
                                if (val.product != undefined && val.storage != undefined && val.product.id == product.id)
                                    return <li key={index}>{`${val.storage.name} - ${val.count}`} {measurement_unit}</li>;
                            })
                        }
                    </ul>
                    :
                    <p style={{ paddingLeft: "10px" }}>У вас нема резервів!</p>
            }

            <p className="h6">Залишки на складах:</p>
            <ul>
                {(product.productBalanceInStorages != undefined) && product.productBalanceInStorages.map((v, index) => {
                    if (v.count > 0)
                        return (
                            <li key={index}>
                                {(v.storage != undefined) && v.storage.name} ({(v.manager != undefined) && `${v.manager.firstName} ${v.manager.lastName}`}) - <strong>{v.count}</strong> {measurement_unit}
                            </li>
                        );
                })}

            </ul>
        </div>
    );
}



const StorageBalance = ({product}) => {
    const reduxBalance = useSelector(state => state.balance)
    const measurment = ( product.measurement_unit != undefined) ?  product.measurement_unit.short_name: '';

    return (
        <Card body>
            <h3>Залишки</h3>
            <span>{(product.balance > 0) ? 'Баланс на складі - ' + product.balance.toFixed(3) + ' ' + measurment : 'Нема на складі'}</span>
            
            <MyPopovers product={product} reduxBalance={reduxBalance} measurement_unit={measurment} />
        </Card>

    );
}

export default StorageBalance;