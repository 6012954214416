import React, { useRef, useState, useEffect } from 'react';
import { Link, useLoaderData } from 'react-router-dom';
import AxiosInstance from 'src/components/Axios';
import { useNavigate } from "react-router-dom";
import {
  CForm,
  CFormLabel,
  CFormInput,
  CButton,
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CRow,
} from '@coreui/react';
import FormOrders from 'src/components/pre_orders/FormOrders';
import ViewOrders from 'src/components/pre_orders/ViewOrders';
import FormProductOrders from 'src/components/pre_orders/FormProductOrders';
import ViewProductOrders from 'src/components/pre_orders/ViewProductOrders';
import BasicModal from 'src/components/Modal';
import CreateOrderFromPreOrder from 'src/components/orders/CreateOrderFromPreOrder';
import WarningDontHaveManager from 'src/components/pre_orders/WarningDontHaveManager';

const urlApi = '/pre_orders';
const urlRouter = '/pre-orders';

export async function LoaderPreOrdersUpdate({ params }) {
  if (Object.keys(params).length != 0) {
    let res = await AxiosInstance.get(`${urlApi}/${params.pid}`).then((response) => {
      return response;
    });
    return res;
  }
  return false;
}

const formReducer = (state, event) => {
  return {
    ...state,
    [event.target.name]: event.target.value
  }
}

const PreOrdersForm = () => {
  const { data } = useLoaderData();

  const [MyData, setMyData] = useState(data);
  const [show, setShow] = useState((data == undefined) ? false : true);

  // Чи можна редагувати онлайн замовлення
  const isChangePreOrders = process.env.REACT_APP_IS_CHANGE_PRE_ORDERS;

  // Для створення замовлення на основі preOrder
  const createOrder = CreateOrderFromPreOrder();

  // Відкрити/закрити вікно з попередженням про відсутність менеджера в контрагента при реалізації попереднього замовлення
  const [openWarning, setOpenWarning] = useState(false);
  // Акаунт для модального вікна з ппередженням
  const [accountForModal, setAccountForModal] = useState(false);

  useEffect(() => {
    setMyData(data);
  }, [data]);

  const [name, setName] = useState((data != undefined) ? data.name : '');
  const [synonym, setSynonym] = useState((data != undefined) ? data.synonym : '');
  const [mainImg, setmainImg] = useState((data != undefined) ? data.images : '');

  const [showModal, setShowModal] = useState(true);

  const actionShowModal = (rez) => {
    setShowModal(rez);
    if (!rez)
      getParent();
  };

  // Функція для реалізації попереднього замовлення
  const realizePreOrder = (preOrder) => {
    // console.log('preOrder', preOrder);
    // Дані для реалізації
    createOrder.setOrder(preOrder);
    // Дані для модального вікна
    setAccountForModal(preOrder.account);
  };

  // Відслідковувати чи відкрити модальне вікно з попередженням про відсутність менеджера
  useEffect(() => {
    if (createOrder.isOpenWarningMessage) {
      setOpenWarning(true);
    }
    else {
      setOpenWarning(false);
    }
  }, [createOrder.isOpenWarningMessage])

  // Функція для закриття модального вікна з попередженням про відсутність менеджера
  const handleCloseWarning = () => {
    // Очистити дані для модального вікна
    setAccountForModal(false);
    createOrder.setIsOpenWarningMessage(false);
    createOrder.setOrder(false);
    createOrder.setTypeMessage("");
  };

  return (
    <>
      {/* Модальне вікно з попередженням про відсутність менеджера або договору в контрагента при реалізації попереднього замовлення */}
      {(openWarning != false && createOrder.typeMessage != "") &&
        <WarningDontHaveManager openWarning={openWarning} handleCloseWarning={handleCloseWarning} accountForModal={accountForModal} typeMessage={createOrder.typeMessage} />
      }

      <CCard className="mb-4">
        <CCardHeader className="d-flex justify-content-between">
          <div><strong>Дані замовлення</strong> <small>{(data != undefined) ? `#${MyData.id}` : ''}</small></div>
          {
            (MyData != undefined && MyData.orders != undefined && MyData.orders.length == 0) &&
            <CButton
              color='secondary'
              onClick={() => realizePreOrder(MyData)}
              title='Реалізувати онлайн замовлення'
            >
              Реалізувати
            </CButton>
          }
        </CCardHeader>
        <CCardBody>
          {(show) ? <ViewOrders data={MyData} /> : <FormOrders data={MyData} />}

        </CCardBody>
      </CCard>
      {(data != undefined) ?
        <CCard className="mb-4">
          <CCardHeader>
            <strong>Товари</strong>
            {/* Відображати якщо дозволено редагування онлайн замовлення (чомусь працює обернено!, тому "!isChangePreOrders") */}
            {
              !isChangePreOrders &&
              <BasicModal
                show={showModal}
                actionShowModal={actionShowModal}
                title={`Додати товар до замовлення №${MyData.id}`}
                content={
                  <FormProductOrders data={MyData} actionShowModal={actionShowModal} />
                }
              />
            }
          </CCardHeader>
          <CCardBody>
            {(show) ? <ViewProductOrders data={MyData} /> : <FormProductOrders data={MyData} />}
          </CCardBody>
        </CCard>
        : ''
      }



      {/* <div className="mb-3">
          <CFormLabel htmlFor="exampleFormControlInput1">Назва</CFormLabel>
          <CFormInput type="text" id="exampleFormControlInput1" value={name} onChange={(e) => setName(e.target.value)} />
        </div>
        <div className="mb-3">
          <CFormLabel htmlFor="exampleFormControlInput1">Синонім</CFormLabel>
          <CFormInput type="text" id="exampleFormControlInput2" value={synonym} onChange={(e) => setSynonym(e.target.value)} />
        </div>
        <div className="mb-3">
          <CFormLabel htmlFor="exampleFormControlInput1">Головне зображення</CFormLabel>
          <CFormInput type="text" id="exampleFormControlInput3" value={mainImg} onChange={(e) => setmainImg(e.target.value)} />
          <Link to="/media" target='_blanck'>перейти до медіа</Link>
        </div>
        <div className="mb-3">
          <Editor
            apiKey='dvfh34ptn34xsf8t99ap6flco4fcps87169njgk0khjz1ylp'
            onInit={(evt, editor) => editorRef.current = editor}
            initialValue={(data != undefined) ? data.content : ''}
            init={{
              height: 500,
              menubar: false,
              plugins: [
                'advlist autolink lists link image charmap print preview anchor',
                'searchreplace visualblocks code fullscreen',
                'insertdatetime media table paste code help wordcount'
              ],
              toolbar: 'undo redo | formatselect | ' +
                'bold italic backcolor | alignleft aligncenter ' +
                'alignright alignjustify | bullist numlist outdent indent | ' +
                'removeformat | help',
              content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
            }}
          />
        </div> */}


    </>
  );
};

export default PreOrdersForm;
