import React, { useEffect, useRef, useState } from 'react';
import {
  CForm,
  CFormLabel,
  CFormInput,
  CButton, CRow, CCol, CCard, CCardHeader, CCardBody
} from '@coreui/react';
import { Link, useLoaderData, useNavigate } from 'react-router-dom';
import axios from "axios";
import Select from 'react-select'
import { DataGrid } from '@mui/x-data-grid';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import {
  cilPen,
  cilPlus,
  cilTrash
} from '@coreui/icons';
import CIcon from '@coreui/icons-react';
import AxiosInstance from 'src/components/Axios';
import BasicModal from 'src/components/Modal';
import MediaFrom from '../media/MediaFrom';
import MyToastify from 'src/components/myComponents/MyToactify';

const initialValues = {
  // id: "",
  name: "",
  slug: "",
  orders: 0,
};


const MenuItemForm = ({ menu_id, id, parent_id, actionShowModal, data }) => {

  // console.log("data", data);

  const [values, setValues] = useState(initialValues);
  const [file, setFile] = useState(false);
  const [media, setMedia] = useState();
  const [mediaUrl, setMediaUrl] = useState(false);

  // Викликає popup з власного компонента
  const popup = MyToastify();
  // Текст для popup
  const popupTextPutSuccess = "Пункт меню оновлено";
  const popupTextPostSuccess = "Пункт меню створено";
  const popupTextPutWarning = "Пункт меню не оновлено";
  const popupTextPostWarning = "Пункт меню не створено";

  useEffect(() => {
    if (parent_id != undefined)
      setValues({ ...values, parent: `api/menu_items/${parent_id}` })
    else
      setValues({ ...values, menu: `api/menus/${menu_id}` })
  }, []);

  useEffect(() => {
    if (data != undefined) {
      // console.log(data);
      setValues({
        name: data.name,
        slug: data.slug,
        orders: data.orders,
        media: data.media,
      })
      if (data.media != undefined)
        setMedia(data.media.match(/(\d+)/)?.[0])
    }
  }, [data])

  useEffect(() => {
    // console.log(media);
    if (media != undefined) {
      setValues({
        ...values,
        media: `/api/media_objects/${media}`
      });

      const response = AxiosInstance.get(`/media_objects/${media}`).then((response) => {
        if (response.status == 200) {
          setMediaUrl(response.data.contentUrl)
        }
      });
    }
  }, [media]);

  const handleInputChange = (e) => {
    const { name, value, type } = e.target;
    setValues({
      ...values,
      [name]: type === "number" ? parseInt(value, 10) : value,
    });
  };

  /** Відправка форми */
  const handleSubmit = event => {
    event.preventDefault();

    if (data == undefined) {
      const response = AxiosInstance.post('/menu_items', values).then((response) => {
        if (response.status == 201) {
          // Popup про успішні зміни
          popup.toastifySuccess(popupTextPostSuccess);
          // actionShowModal(setShowModalItem(!rez), getItems()); перший параметр (закрити модальне вікно), другий - оновити дані)
          actionShowModal(false, true);
          // navigate(`/menu/update/${response.data.id}`);
        } else {
          popup.toastifyWarning(popupTextPostWarning);
        }
      });
    } else {
      const response = AxiosInstance.put('/menu_items/' + data.id, values).then((response) => {
        if (response.status == 200) {
          // Popup про успішні зміни
          popup.toastifySuccess(popupTextPutSuccess);
          // actionShowModal(setShowModalItem(!rez), getItems()); перший параметр (закрити модальне вікно), другий - оновити дані)
          actionShowModal(false, true);
        } else {
          popup.toastifyWarning(popupTextPutWarning);
        }
        // if(response.status == 201){
        //   navigate(`/menu/update/${response.data.id}`);
        // }
      });
    }
    // const response = AxiosInstance.put('/menus/' + data.id, values);     
    // }
  }

  return (
    <>
      <div className="mb-3 mt-3 media-menu">
        {(mediaUrl && mediaUrl != undefined) ? <img width={40} height={40} src={process.env.REACT_APP_SERVER_URL + mediaUrl} /> : ''}
        <MediaFrom type="category" setNewMediaId={setMedia} toastifySuccess={popup.toastifySuccess} />
      </div>
      <CForm onSubmit={handleSubmit}>
        <div className="mb-3">
          <CFormLabel htmlFor="exampleFormControlInput1">Назва</CFormLabel>
          <CFormInput type="text" name="name" defaultValue={values.name} onChange={handleInputChange} />
        </div>
        <div className="mb-3">
          <CFormLabel htmlFor="exampleFormControlInput1">URL</CFormLabel>
          <CFormInput type="text" name="slug" defaultValue={values.slug} onChange={handleInputChange} />
        </div>
        <div className="mb-3">
          <CFormLabel htmlFor="exampleFormControlInput1">Сортування</CFormLabel>
          <CFormInput type="number" name="orders" value={values.orders} onChange={handleInputChange} />
        </div>
        <CButton type="submit" color="success">Зберегти</CButton>
      </CForm>
    </>
  );
}

export default MenuItemForm;