import React, { useEffect, useRef, useState } from 'react';
import { CForm, CFormLabel, CFormInput, CButton, CRow, CCol, CCard, CCardHeader, CCardBody } from '@coreui/react';
import { Link, useLoaderData, useNavigate } from 'react-router-dom';
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import AxiosInstance from 'src/components/Axios';
import MenuItemForm from 'src/components/menu/MenuItemForm';
import BasicModal from 'src/components/Modal';
import { BsPlusLg, BsSortNumericDown, BsFillTrashFill } from 'react-icons/bs';
import '../../App.css';
import PermissionsNoPage, { Permissions } from "src/components/Permissions";
import MyAccordion from 'src/components/menu/MyAccordion';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import MyToastify from 'src/components/myComponents/MyToactify';

export async function LoaderMenuUpdate({ params }) {
  if (params.menuId != undefined) {
    let res = await AxiosInstance.get('/menus/' + params.menuId).then((response) => {
      return response;
    });

    return res;
  }
  return false;
};

const initialValues = {
  // id: "",
  name: "",
  type: "",
};


const MenuForm = ({ user_data }) => {
  if (!Permissions(user_data, 'Menu', 'EDIT_MENU')) {
    return <PermissionsNoPage />;
  }
  const navigate = useNavigate();
  const { data } = useLoaderData();
  const [items, setItems] = useState([]);
  // console.log(data);
  /** Дані форми */
  const [values, setValues] = useState(initialValues);
  const [showModal, setShowModal] = useState(false);

  // Викликає popup з власного компонента
  const popup = MyToastify();
  // Текст для popup
  const popupTextPutSuccess = "Меню оновлено";
  const popupTextPostSuccess = "Меню створено";
  const popupTextPutWarning = "Меню не оновлено";
  const popupTextPostWarning = "Меню не створено";

  useEffect(() => {
    if (data != undefined) {
      getItems();
      EditMenu(data);
    }
  }, [data]);

  const actionShowModal = (rez, isUpdate = false) => {
    setShowModal(rez);
    if (!rez && isUpdate)
      getItems();
  };

  function getItems() {
    AxiosInstance.get('/menu_items?menu.id=' + data.id).then((response) => {
      setItems(response.data['hydra:member']);
    });
  };

  function EditMenu(row) {
    setValues({
      ...values,
      // id: row.id,
      name: row.name,
      type: row.type,
    });
  };

  const handleInputChange = (e) => {
    const { name, value, type } = e.target;

    setValues({
      ...values,
      [name]: type === "number" ? parseInt(value, 10) : value
    });
  };

  /** Відправка форми */
  const handleSubmit = async event => {
    event.preventDefault();

    if (data == undefined) {
      const response = await AxiosInstance.post('/menus', values).then((response) => {
        if (response.status == 201) {
          // Popup про успішні зміни
          popup.toastifySuccess(popupTextPostSuccess);
          navigate(`/menu/update/${response.data.id}`);
        } else {
          popup.toastifyWarning(popupTextPostWarning);
        }
      });
    } else {
      const response = await AxiosInstance.put('/menus/' + data.id, values).then((response) => {
        if (response.status == 200) {
          // Popup про успішні зміни
          popup.toastifySuccess(popupTextPutSuccess);
          //   navigate(`/menu/update/${response.data.id}`);
        } else {
          popup.toastifyWarning(popupTextPutWarning);
        }
      });
    }
    // const response = AxiosInstance.put('/menus/' + data.id, values);
    // }
  }

  return (
    <>
      {/* Popup про успішні зміни (справа зверху) */}
      <ToastContainer />

      <h1></h1>
      <CRow>
        <CCol xs={12}>
          <CCard className="mb-4">
            <CCardHeader>
              <strong>{(data != undefined) ? data.name : 'Створити'}</strong> <small>*</small>
            </CCardHeader>
            <CCardBody>
              <CForm onSubmit={handleSubmit}>
                <div className="mb-3">
                  <CFormLabel htmlFor="exampleFormControlInput1">Назва</CFormLabel>
                  <CFormInput type="hidden" defaultValue={values.id} />
                  <CFormInput type="text" name="name" defaultValue={values.name} onChange={handleInputChange} />
                </div>
                <div className="mb-3">
                  <CFormLabel htmlFor="exampleFormControlInput1">Тип</CFormLabel>
                  <CFormInput type="text" name="type" defaultValue={values.type} onChange={handleInputChange} />
                </div>
                <CButton type="submit" color="success">Зберегти</CButton>
              </CForm>
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>

      {(data != undefined) &&
        <CRow>
          <CCol xs={12}>
            <CCard className="mb-4">
              <CCardHeader>
                <strong>Пункти меню</strong> <small>*</small>
              </CCardHeader>
              <CCardBody>
                <div className='p-5'>
                  <MyAccordion items={items} getItems={getItems} />
                </div>
                <BasicModal
                  show={showModal}
                  actionShowModal={actionShowModal}
                  title={`Додати пукт меню до - ${data.name}`}
                  btn_name={<><BsPlusLg /> Додати пункт меню</>}
                  content={
                    // <MenuItemForm menu_id={data.id} actionShowModal={actionShowModal} key="0" />
                    <MenuItemForm menu_id={data.id} actionShowModal={actionShowModal} activeKey="0" />
                  }
                />
              </CCardBody>
            </CCard>
          </CCol>
        </CRow>
      }
    </>
  );
};

export default MenuForm;