import React, { useEffect, useState } from 'react';
import Offcanvas from 'react-bootstrap/Offcanvas';
import Button from 'react-bootstrap/Button';
import ViewOrders from './ViewOrders';
import ViewProductOrders from './ViewProductOrders/ViewProductOrders';
import { confirmAlert } from 'react-confirm-alert';
import AxiosInstance from 'src/components/Axios';
import { Link, useLoaderData } from 'react-router-dom';


import { CCard, CCardBody, CCardHeader, CFormInput, CCol, CRow, CButton, CAccordion, CAccordionItem, CAccordionHeader, CAccordionBody } from '@coreui/react';


const OffcanvasOrder = ({show, handleClose, data}) => {


    const [sumOrder, setSumOrder] = useState(0);
    const [order, setOrder] = useState(false);
    useEffect(() => {
        AxiosInstance.get(`/orders/${data.id}`).then((response) => {
            setOrder(response.data);
        });
    }, [data]);


    const syncedApprovedClick = (type) => {
        confirmAlert({
          title:  'Увага!',
          message: 'Ви впевнені що хочете провестинакладну в 1с?',
          buttons: [
            {
              label: 'Так',
              onClick: () => {
                AxiosInstance.put(`${urlApi}/${order.id}`, { status: 'approved_in_1c' })
                let res = AxiosInstance.get(`${urlApi}/${order.id}`).then((response) => {
                //   setMyData(response.data)
                  return response;
                });
              }
            },
            {
              label: 'Ні',
              onClick: () => {
                return;
              }
            }
          ]
        });
      }

    return(
        <Offcanvas show={show} onHide={handleClose}>
            {
                (order) &&
                    <>
                        <Offcanvas.Header closeButton>
                        <Offcanvas.Title>Розхідна #{ order.id}</Offcanvas.Title>
                        </Offcanvas.Header>
                        <Offcanvas.Body>

                            <div className='d-flex justify-content-center align-items-center'>
                                { (order.status1c == 'save') && <>💾 Збережено в 1с </>}
                                { (order.status1c == 'approved') && <>✅ Проведено в 1с</>}
                                { (order.status1c == 'remove') && <>❌ позначено на видалення в 1с</>}
                                { (order.status1c == 'deleted ') && <>❌ видалено в 1с</>}

                                { ((order.status == 'success_1c' || order.status == null) && order.status1c == 'save') && <CButton style={{marginLeft: '50px'}} onClick={() => { syncedApprovedClick(false) }}>✅ Провести в 1с</CButton>}
                                {/* { (order.status == 'approved_in_1c' && order.status1c == 'save') && <>Очікує синхронізації</>} */}
                                { ((order.status1c == 'save' || order.status1c == null) && (order.status == 'synced_approved_in_1c' || order.status == 'synced_in_1c' || order.status == 'approved_in_1c') ) && <>🔄 Очікує синхронізації</>}
                                { (order.status == 'is_forming') && <>📝формується</>}
                                { (order.status == 'error_approved' || order.status1c == 'error_approved') && <>❗ Помилка проведення, перевірте журнал 1с</>}
                            </div>
                            <hr/>
                            <div className="row">
                                <div className="col">
                                    <h3>{order.code1c}</h3>
                                </div>
                                <div className="col">
                                    <Link className="btn btn-light" to={'/orders/update/' + order.id} >Редагувати</Link>
                                </div>
                            </div>
                            <hr/>


                            <ViewOrders data={data} />
                            <hr/>
                            <h4>Товари</h4>
                            <ViewProductOrders  type="main" data={data} setSumOrder={setSumOrder} getOP={true} />
                            
                        </Offcanvas.Body>
                    </>
            }
        </Offcanvas>
    )
}


export default OffcanvasOrder;