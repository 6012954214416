import React, { useEffect, useRef, useState } from 'react';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import AxiosInstance from 'src/components/Axios';
import BasicModal from 'src/components/Modal';
import Accordion from 'react-bootstrap/Accordion';
import { BsPlusLg, BsSortNumericDown, BsFillTrashFill } from 'react-icons/bs';
import { AiOutlineArrowDown, AiFillEdit } from 'react-icons/ai';
import Button from 'react-bootstrap/Button';
import { sortObj } from "src/components/store/sortObj";
import MenuItemForm from './MenuItemForm';
import MyAccordion from './MyAccordion';
import '../../App.css';
import MyToastify from '../myComponents/MyToactify';


const AccordionItemMenu = ({ data, getItems }) => {
    const [showModalItemCreate, setShowModalItemCreate] = useState(false);
    const [showModalItemUpdate, setShowModalItemUpdate] = useState(false);

    // Викликає popup з власного компонента
    const popup = MyToastify();
    // Текст для popup
    const popupTextDeleteSuccess = "Пункт меню видалено";
    const popupTextDeleteWarning = "Пункт меню не видалено. Можливо, в меню яке Ви видаляєте є підпункти. Спочатку потрібно видалити їх.";

    const actionShowModalItemCreate = (rez, isUpdate = false) => {
        setShowModalItemCreate(rez);
        if (!rez && isUpdate)
            getItems();
    };

    const actionShowModalItemUpdate = (rez, isUpdate = false) => {
        setShowModalItemUpdate(rez);
        if (!rez && isUpdate)
            getItems();
    };

    function Deleted(row, getItems) {
        confirmAlert({
            title: 'Увага!',
            message: `Ви впевнені що хочите видалити цей "${row.name}" пункт ?`,
            buttons: [
                {
                    label: 'Так',
                    onClick: () => {
                        const response = AxiosInstance.delete(`/menu_items/${row.id}`).then((response) => {
                            if (response.status == 204) {
                                // Popup про успішні зміни
                                popup.toastifySuccess(popupTextDeleteSuccess);
                                getItems();
                            } else {
                                popup.toastifyWarning(popupTextDeleteWarning);
                            }
                        })
                    }
                },
                {
                    label: 'Ні',
                    onClick: () => {
                        return;
                    }
                }
            ]
        });
    }

    return (
        <>
            <Accordion.Item eventKey={data.id}>
                <Accordion.Header>
                    {data.name} <small className='SortPunkt'><BsSortNumericDown /> - {data.orders}</small>
                </Accordion.Header>
                <Accordion.Body className='bg-light'>
                    <div className='row'>
                        <div className='col-3'>
                            <BasicModal
                                show={showModalItemCreate}
                                actionShowModal={actionShowModalItemCreate}
                                title={`Додати пукт меню до - ${data.name}`}
                                btn_name={<><BsPlusLg /> Додати пункт під меню</>}
                                content={
                                    <MenuItemForm menu_id={data.id} actionShowModal={actionShowModalItemCreate} parent_id={data.id} />
                                }
                            />
                        </div>
                        <div className='col-2'>
                            <BasicModal
                                show={showModalItemUpdate}
                                actionShowModal={actionShowModalItemUpdate}
                                title={`Редагувати пукт меню  - ${data.name}`}
                                btn_name={<><AiFillEdit /> Редагувати</>}
                                content={
                                    <MenuItemForm menu_id={data.id} data={data} actionShowModal={actionShowModalItemUpdate} parent_id={data.id} />
                                }
                            />
                        </div>
                        <div className='col justify-content-end d-flex'>
                            <Button variant="danger" onClick={() => Deleted(data, getItems)}><BsFillTrashFill /> Видалити пункт меню</Button>
                        </div>
                    </div>


                    <hr />
                    <h5><AiOutlineArrowDown />Пункти під меню "{data.name}": </h5>

                    {
                        (data.children != undefined && data.children.length > 0) &&
                        <MyAccordion items={data.children} getItems={getItems} activeKey={data.id} />
                    }
                </Accordion.Body>
            </Accordion.Item>
        </>
    );
};

export default AccordionItemMenu;