export function Permissions(user_data, module, operation) {
    // console.log(user_data);
    // console.log(module);
    if (user_data != '' && user_data != undefined) {
        let user = JSON.parse(user_data);

        /**  Якщо Адмін */
        let admin = user.roles.filter(row => row == 'ROLE_ADMIN');
        if (admin.length > 0)
            return true;

        /** чи має  право користуватись адмін панелю */
        if (!user.isAdmminPanel)
            return false;

        /** Права доступу */
        let permission = user.permissions.filter(p => p.entity === module && p.operation === operation);
        if (permission.length > 0)
            return true;
    }





    return false;

}

const PermissionsNoPage = () => {
    return <>403 Нема прав!</>
}

export default PermissionsNoPage;