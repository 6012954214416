import React, { useState, useEffect } from 'react';
import BasicModal from '../Modal';
import MediaFrom from '../media/MediaFrom';
import AxiosInstance from '../Axios';
import { CListGroup, CListGroupItem, CButton } from '@coreui/react';
import { Link } from 'react-router-dom';
import { confirmAlert } from 'react-confirm-alert';
import { cilTrash, cilCopy } from '@coreui/icons';
import CIcon from '@coreui/icons-react';

// Компонент для додавання файлів та виоду списку з файлами до матеріалу
const MaterialsMedia = (props) => {
    // console.log('MaterialsMedia props', props);

    const urlApi_1 = "/material_media";
    // const [showModalMedia, setShowModalMedia] = useState(true); // Для модального вікна "Додати зображення"
    const [showModalMedia, setShowModalMedia] = useState(false); // Для модального вікна "Додати зображення"
    const [newMedia, setNewMedia] = useState(""); // id для щойнододаного файлу
    let i = 0; // Порядковий номер для доданих файлів

    const popupTextPostSuccess = "Файл додано";
    const popupTextPostWarning = "Файл не додано";
    const popupTextDeleteSuccess = "Файл видалено";
    const popupTextDeleteWarning = "Файл не Delete";

    // Обновити після додавання/видалення зображення продукту

    // Функція для створення materia_media
    const createMaterialMedia = async () => {
        const article = {
            name: newMedia.name,
            materials: `/api/materials/${props.data.id}`,
            media: `/api/media_objects/${newMedia.id}`,
        }
        try {
            const response = await AxiosInstance.post(urlApi_1, article);
            // console.log('material_media post response', response);
            props.toastifySuccess(popupTextPostSuccess);
            setNewMedia("");
            // Оновити дані по матеріалу
            props.getMaterials();
        } catch (error) {
            console.error("Помилка запиту на створення material_media", error);
            props.toastifyWarning(popupTextPostWarning);
        };
    };

    // Зробити запит на створення material_media
    useEffect(() => {
        if (newMedia !== "") {
            createMaterialMedia(newMedia);
        };
    }, [newMedia]);

    // Для модального вікна "Додати зображення"
    const actionShowModalMedia = (rez) => {
        setShowModalMedia(rez);
    };

    // Функція для видалення файлу
    const deleteMaterialMedia = async (materialMedia) => {
        try {
            const response = await AxiosInstance.delete(`${urlApi_1}/${materialMedia.id}`);
            // console.log('material_media delete response', response);
            props.toastifySuccess(popupTextDeleteSuccess);
            // Оновити дані по матеріалу
            props.getMaterials();
        } catch (error) {
            console.error("Помилка запиту на видалення файлу", error);
            props.toastifyWarning(popupTextDeleteWarning);
        };
    };

    // Функція для підтвердження видалення
    function Deleted(material) {
        confirmAlert({
            title: 'Ви впевнені що хочите видалити цей пункт ?',
            message: 'Are you sure to do this.',
            buttons: [
                {
                    label: 'Так',
                    onClick: () => {
                        deleteMaterialMedia(material);
                    }
                },
                {
                    label: 'Ні',
                    onClick: () => { return }
                }
            ]
        });
    };

    return (
        <div>
            <h3 className="mb-2">Матеріали</h3>

            <div className="mb-3">
                <BasicModal
                    show={showModalMedia}
                    actionShowModal={actionShowModalMedia}
                    title={`Додати файл`}
                    btn_name='Додати файл'
                    content={
                        <MediaFrom product_id={props.data.id}
                            actionShowModal={actionShowModalMedia}
                            toastifySuccess={props.toastifySuccess}
                            type="materials"
                            setNewMedia={setNewMedia}
                        />
                    }
                />
            </div>

            {/* Список з допоміжними спеціальностями */}
            {
                (props.data.materialMedia.length > 0) ?
                    <CListGroup className="mb-2">
                        {
                            props.data.materialMedia.map((material) => {
                                // console.log('material', material);
                                i++;

                                return (
                                    <CListGroupItem key={material.id}>
                                        {/* <div className="d-flex justify-content-between align-items-start"> */}
                                        <div className="d-flex align-items-start">
                                            <div className="me-3">
                                                <span className='me-2'>{`${i})`}</span>
                                                {material.name}
                                            </div>
                                            <div>
                                                <Link
                                                    to={`${process.env.REACT_APP_SERVER_URL}${material.media.contentUrl}`}
                                                    target="_blank" title="Відкрити файл в новій вкладці"
                                                >
                                                    {`${process.env.REACT_APP_SERVER_URL}${material.media.contentUrl}`}
                                                </Link>
                                            </div>
                                            <CButton type="submit" color="dark" className='ms-auto me-2'
                                                onClick={() => {
                                                    navigator.clipboard.writeText(`${process.env.REACT_APP_SERVER_URL}/${material.media.contentUrl}`)
                                                }}
                                                title="Скопіювати посилання на файл"
                                            >
                                                <CIcon icon={cilCopy} customClassName="nav-icon" height={20} />
                                            </CButton>
                                            <CButton
                                                color="danger" variant="outline" onClick={() => { Deleted(material) }}
                                                title="Видалити зв'язок на файл"
                                            >
                                                Видалити
                                            </CButton>
                                        </div>
                                    </CListGroupItem>
                                )
                            })
                        }
                    </CListGroup >
                    :
                    <p className="ml-3 mb-2">Матеріали (файли) відсутні</p>
            }
        </div>
    );
};

export default MaterialsMedia;