import React, {useState, useEffect} from 'react';
import {
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CRow,
  CButton
} from '@coreui/react';
import { DataGrid } from '@mui/x-data-grid';
import {Link, useLoaderData, useLocation} from 'react-router-dom';
import axios from "axios";
import PermissionsNoPage, {Permissions} from "src/components/Permissions";
import AxiosInstance from 'src/components/Axios';
import { confirmAlert } from 'react-confirm-alert';
import CIcon from '@coreui/icons-react';
import { cilPen, cilPlus, cilTrash, cilLightbulb } from '@coreui/icons';
import { formatUTC } from 'src/store';

const page = 1;
const postPerPage = 0;
const pageSize = 20;

export async function LoaderBlog({ params }) {
  let prm = {page: 1};
  if(params.type != undefined)
    prm['type'] = params.type;

  let res = await AxiosInstance.get('/posts', {params: prm}).then((response) => {
    response['type'] = (params.type != undefined)? params.type : "page";
    // console.log(response)
    return response;
  });
  if(res.type == undefined)
    res['type'] = (params.type != undefined)? params.type : "page";

  return res;
}


const rows = [];



const Blog = ({user_data}) => {
  if(!Permissions(user_data, 'Blog', 'VIEW_BLOG')){
    return <PermissionsNoPage />;
  }

  const { data, type } = useLoaderData();
  const [rows, setRows] = useState(data['hydra:member']);
  const postPerPage = page * data['hydra:totalItems'];
  const location = useLocation();

  const getRowsTable = (p = 1) => {
    let prm = {page: p};
    if(type != 'post')
      prm['type'] = type;

    AxiosInstance.get('/posts', {params: prm}).then((response) => {
      setRows(response.data['hydra:member']);
    });
  };

  useEffect(()=> {
    getRowsTable();
  }, [location]);

  const handlePageChange = (page) => {
    getRowsTable(page + 1);
  };

  function Deleted(row) {
    confirmAlert({
      title: 'Ви впевнені що хочете видалити цей пункт ?',
      message: 'Are you sure to do this.',
      buttons: [
        {
          label: 'Так',
          onClick: () => {
            const response = AxiosInstance.delete('/posts/' + row.id).then((response) => {
              getRowsTable();
            });
          }
        },
        {
          label: 'Ні',
          onClick: () => {
            return;
          }
        }
      ]
    });
  };

  function Hide(row) {
    confirmAlert({
      title: 'Ви впевнені що хочете приховати/показати пункт ?',
      message: 'Are you sure to do this.',
      buttons: [
        {
          label: 'Так',
          onClick: () => {
            AxiosInstance.put('/posts/' + row.id, {active: row.active !== undefined ? !row.active : true}).then(() => {
              getRowsTable();
            });
          }
        },
        {
          label: 'Ні',
          onClick: () => {
            return;
          }
        }
      ]
    });
  };


  const columns = [
    { field: 'id', headerName: 'ID', width: 70 },
    { field: 'name', headerName: 'Назва', width: 200 },
    { field: 'content', headerName: 'Опис', width: 400 },
    {
      field: 'dateEntered',
      headerName: 'Дата',
      type: 'date',
      width: 160,
      renderCell: (params)=>{
        return <div>{formatUTC(params.row.dateEntered)}</div>
      }
    },
    {
      field: "action",
      headerName: "Action",
      sortable: false,
      renderCell: (params) => {
        
          if(type != undefined && type != 'page')
              return <Link to={`/post/${type}/${params.row.id}`}>
                      <CButton color="dark" variant="outline" title="Редагувати">
                        <CIcon icon={cilPen} customClassName="nav-icon" height={20} />
                        </CButton>
                      </Link>
          else
            return <Link to={'/post/update/' + params.row.id}>
                    <CButton color="dark" variant="outline" title="Редагувати">
                      <CIcon icon={cilPen} customClassName="nav-icon" height={20} />
                    </CButton>
                    </Link>
      }
    },
    {
      field: "active",
      headerName: "Приховати",
      sortable: false,
      renderCell: (params) => {
        return <CButton color={params.row.active ? "success" : "danger"} variant="outline" onClick={() => {
          Hide(params.row)
        }
        }><CIcon icon={cilLightbulb} customClassName="nav-icon" height={20} /></CButton>;
      }
    },
    {
      field: "delete",
      headerName: "Видалити",
      sortable: false,
      renderCell: (params) => {
        return <CButton color="danger" variant="outline" onClick={() => {
          Deleted(params.row)
        }
        }><CIcon icon={cilTrash} customClassName="nav-icon" height={20} /></CButton>;
      }
    },
  ];


    return (
      <CRow>
        <CCol xs={12}>
          <CCard className="mb-4">
            <CCardHeader>
              <strong>Пости</strong>
            </CCardHeader>
            <CCardBody>
              {/* <p className="text-medium-emphasis small">
                <Link to="/post/create">Створити</Link>
              </p> */}
              <p className="text-medium-emphasis small">
              {
                (type != undefined && type != 'page')?
                    <Link to={`/post/${type}/create`}><CButton color="secondary"><CIcon icon={cilPlus} customClassName="nav-icon" height={15} />Створити</CButton></Link>
                :
                  <Link to="/post/create"><CButton color="secondary"><CIcon icon={cilPlus} customClassName="nav-icon" height={15} />Створити</CButton></Link>
              }
            </p>
              <div style={{ height: 600, width: '100%' }}>

              <DataGrid
                rows={rows}
                columns={columns}
                pageSize={pageSize}
                rowsPerPageOptions={[postPerPage]}
                // checkboxSelection
              />
              </div>
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>
    );
};

export default Blog;
