import React, { useEffect, useState } from 'react';
import { CForm, CFormLabel, CFormInput, CButton, CCard, CCardBody, CCardHeader, CCol, CRow, CFormCheck, CNav, CNavItem, CNavLink } from '@coreui/react';

// Вкладка "Ціна"
const ProductPrice = (props) => {

    const [price, setPrice] = useState((props.data != undefined) ? props.data.price : '');
    const [discounts, setDiscounts] = useState((props.data != undefined) ? props.data.discounts : '');
    const [oldPrice, setOldPrice] = useState((props.data != undefined) ? props.data.oldPrice : 0);
    const [newOldPrice, setNewOldPrice] = useState((props.data != undefined) ? props.data.price : 0);
    const [isSalle, setIsSalle] = useState((props.data != undefined && props.data.isSalle != null) ? props.data.isSalle : false);


    const data_response = {
        price: parseFloat(price),
        isSalle: isSalle,
        oldPrice: newOldPrice,
        // discounts: parseFloat(discounts),
        active: 'on',
        //     name: name,
        //     description: editorRef.current.getContent(),
        //     // description: description,
        //     code1c: code1c,
        //     barcode: barcode,
        //     status: status,
        //     article: article,
        //     nds: parseFloat(nds),
        //     packaging: parseFloat(packaging),
        //     measurement_unit: `/api/measurment_units/${measurmentUnit.value}`,
    };

    useEffect(() => {
        if (props.MyData) {
            setOldPrice(props.MyData.oldPrice);
            setPrice(props.MyData.price);
        }
    }, [props.MyData]);

    // Вибирати стару ціну
    useEffect(() => {
        if(props.MyData != undefined && props.MyData.price != undefined){
            if (props.MyData.price === price) {
                setNewOldPrice(props.MyData.oldPrice);
            } else {
                setNewOldPrice(props.MyData.price);
            }
        }else{
            setNewOldPrice(0);
        }
    }, [oldPrice, price]);

    return (
        <div>
            <CForm>
                <div className="mb-3">
                    <CFormCheck id="exampleFormControlInput11" label="Акція" checked={isSalle} onChange={() => setIsSalle(!isSalle)} />
                </div>
                <div className="mb-3">
                    <CFormLabel htmlFor="exampleFormControlInput1">Попередня ціна</CFormLabel>
                    <CFormInput type="number" id="exampleFormControlInput7" value={oldPrice == null ? 0 : oldPrice} disabled={true} />
                </div>
                <div className="mb-3">
                    <CFormLabel htmlFor="exampleFormControlInput1">Актуальна ціна</CFormLabel>
                    <CFormInput type="number" id="exampleFormControlInput6" value={price == null ? "" : price} onChange={(e) => setPrice(e.target.value)} autoComplete="off" />
                </div>
                <CButton color="success" onClick={() => props.handleSubmit(event, data_response)}>Зберегти</CButton>
            </CForm>
        </div>
    );
};

export default ProductPrice;