import React, { useEffect, useState } from 'react';

// Функція для виводу назви валют
const useCurrency = ({ currencyIncome }) => {

    const currencyArr = [
        { value: 'uah', label: 'грн' },
        { value: 'usd', label: 'дол' },
        { value: 'eur', label: 'євр' }
    ];

    const [convertedCurrency, setConvertedCurrency] = useState(""); // Яке значення виводити

    useEffect(() => {
        if (currencyIncome) {
            const lowerCaseCurency = currencyIncome.toLowerCase();
            const foundCurrency = currencyArr.find(item => item.value === lowerCaseCurency);
            setConvertedCurrency(foundCurrency ? foundCurrency.label : "");
        }
    }, [currencyIncome]);

    return { convertedCurrency };
};

export default useCurrency;