import React, { useState, useEffect } from 'react';
import AxiosInstance from 'src/components/Axios';
import AsyncSelect from 'react-select/async';

const FilterEvents = ({ setData, clear }) => {
    const [selectedOption, setSelectedOption] = useState(null);
    useEffect(() => {
        // console.log('clear', clear);
        if (clear) {
            setData(null);
            setSelectedOption(null);
        }
    }, [clear])

    // Для поля (AsyncSelect)
    const loadOptions = async (inputValue) => {
        return await AxiosInstance.get(`/events`, {
            headers: { 'accept': 'application/json' }, params: {
                "name": inputValue,
            }
        }).then((response) => {
            return response.data.map((result) => {
                return {
                    label: result.name,
                    value: result.id,
                }
            });
        });
    };

    const handleChange = (selectedOption) => {
        setData(selectedOption);
        setSelectedOption(selectedOption);
    };

    return (
        <>
            <AsyncSelect
                cacheOptions
                loadOptions={loadOptions}
                defaultOptions
                value={selectedOption}
                onChange={handleChange}
                className="flex-grow-1"
                placeholder="Подія"
            // onKeyDown={keyDown}
            />
        </>
    )
}

export default FilterEvents;