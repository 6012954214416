import React, { useState } from 'react';
import { CCard, CCardBody, CCardHeader, CCol, CRow, CButton } from '@coreui/react';
import { DataGrid } from '@mui/x-data-grid';
import { Link, useLoaderData } from 'react-router-dom';
import { cilPen, cilPlus, cilTrash } from '@coreui/icons';
import CIcon from '@coreui/icons-react';
import AxiosInstance from 'src/components/Axios';
import PermissionsNoPage, { Permissions } from "src/components/Permissions";

const page = 1;
const postPerPage = 0;
const pageSize = 20;

export async function LoaderMenu({ params }) {
  let res = await AxiosInstance.get('/menus?page=' + page).then((response) => {
    return response;
  });
  return res;
}

const columns = [
  { field: 'id', headerName: 'ID', width: 70 },
  { field: 'name', headerName: 'Назва', width: 200 },
  { field: 'type', headerName: 'Тип', width: 400 },
  {
    field: "action",
    headerName: "Редагувати",
    sortable: false,
    renderCell: (params) => {
      return <Link to={'/menu/update/' + params.row.id}><CButton color="dark" variant="outline"><CIcon icon={cilPen} customClassName="nav-icon" height={20} /></CButton></Link>;
    }
  },
];


const Menu = ({ user_data }) => {

  if (!Permissions(user_data, 'Menu', 'VIEW_MENU')) {
    return <PermissionsNoPage />;
  }

  const { data } = useLoaderData();
  const [rows, setRows] = useState(data['hydra:member']);
  const postPerPage = 1;

  return (
    <CRow>
      <CCol xs={12}>
        <CCard className="mb-4">
          <CCardHeader>
            <strong>Меню</strong> <small>*</small>
          </CCardHeader>
          <CCardBody>
            {<p className="text-medium-emphasis small">
              <Link to="/menu/create"><CButton color="secondary"><CIcon icon={cilPlus} customClassName="nav-icon" height={15} />Створити</CButton></Link>
            </p>}
            <div style={{ height: 400, width: '100%' }}>
              <DataGrid
                rows={rows}
                columns={columns}
                pageSize={pageSize}
                rowsPerPageOptions={[postPerPage]}
              // checkboxSelection
              />
            </div>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
  );
};

export default Menu;
