import React, { useEffect, useState } from "react";
import MediaFrom from "./MediaFrom";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import MyToastify from 'src/components/myComponents/MyToactify';
import AxiosInstance from "../Axios";
import { CCard, CCardBody, CCardHeader, CFormInput, CCol, CRow, CButton, CAccordion, CAccordionItem, CAccordionHeader, CAccordionBody } from '@coreui/react';
import { confirmAlert } from 'react-confirm-alert';
import { cilPen, cilPlus, cilTrash } from '@coreui/icons';
import CIcon from '@coreui/icons-react';



const MediaBox = ({default_media, setNewMedia}) => {

    const popup = MyToastify();

  /** Media start */
  const [media, setMedia] = useState(default_media);
  const [mediaUrl, setMediaUrl] = useState(false);
  
//   useEffect(() => {
//     if(data != undefined){
//       if(data.media != undefined)
//         setMedia(data.media.id)
//     }
    
//   }, [data])
  useEffect(() => {
    // console.log(media);
    if(media != undefined){
        const response = AxiosInstance.get(`/media_objects/${media.id}`).then((response) => {
          if(response.status == 200){
            setMediaUrl(response.data.contentUrl)
            setNewMedia(response.data)
          }
        });
    }
  }, [media]);

  function Deleted(row) {
    confirmAlert({
      // title: 'Ви впевнені що хочите видалити цей пункт ?',
      title: `Ви впевнені що хочите видалити Зображення?`,
      message: '',
      buttons: [
        {
          label: 'Так',
          onClick: () => {
            const response = AxiosInstance.delete('/media_objects/'+ row.id ).then((response) => {
              setMediaUrl(false);
            });
           
          }
        },
        {
          label: 'Ні',
          onClick: () => {
            return;
          }
        }
      ]
    });
  };

  /** Media end */

    return (
        <>
            <ToastContainer />
            <div className="MediaBox">
                {/* MEDIA */}
                {(mediaUrl && mediaUrl != undefined) ? 
                    <div style={{position: "relative"}}>
                        <CButton color="danger" variant="outline" className="btnTrashMin p-1" onClick={() => {Deleted(media)}} title="Видалити зображення контрагента"><CIcon icon={cilTrash} customClassName="nav-icon" height={15} /></CButton>
                        <img src={process.env.REACT_APP_SERVER_URL + mediaUrl}/>
                    </div>
                : ''}
                {(!mediaUrl || mediaUrl == undefined) && <MediaFrom type="category"  setNewMediaId={setMedia} toastifySuccess={popup.toastifySuccess}  name_field={false} />}
            </div>
            <hr/>
        </>
    );
}

export default MediaBox;
