import React, { useEffect, useRef, useState } from 'react';
import { Editor } from '@tinymce/tinymce-react';
import { Link, useLoaderData } from 'react-router-dom';
import AxiosInstance from 'src/components/Axios';
import { useNavigate } from "react-router-dom";
import { CForm, CFormLabel, CFormInput, CButton } from '@coreui/react';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import MyToastify from 'src/components/myComponents/MyToactify';


const urlApi = '/locations';
const urlRouter = '/locations';
// const tokenStr = localStorage.getItem('user');
// const header = { headers: { "Authorization": `Bearer ${tokenStr}`, 'accept': 'application/json' } }

export async function LoaderLocationsUpdate({ params }) {
  if (Object.keys(params).length != 0) {
    let res = await AxiosInstance.get(`${urlApi}/${params.pid}`).then((response) => {
      return response;
    });
    return res;
  }
  return false;
};

// const formReducer = (state, event) => {
//   return {
//     ...state, 
//     [event.target.name]: event.target.value
//   }
// };

const LocationsForm = () => {
  const navigate = useNavigate();
  const { data } = useLoaderData();
  const [myData, setMyData] = useState((data != undefined) ? data : false);
  const [name, setName] = useState((data != undefined) ? data.name : '');
  const [description, setDescription] = useState((data != undefined) ? data.description : '');
  const editorRef = useRef(null);

  useEffect(() => {
    setMyData(data);
  }, [data]);

  // Викликає popup з власного компонента
  const popup = MyToastify();
  // Текст для popup
  const popupTextPutSuccess = "Підрозділ оновлено";
  const popupTextPostSuccess = "Підрозділ створено";
  const popupTextPutWarning = "Підрозділ не оновлено";
  const popupTextPostWarning = "Підрозділ не створено";

  const handleSubmit = event => {
    event.preventDefault();

    const data_response = {
      name: name,
      description: description,
    };
    if (data != undefined) {
      const response = AxiosInstance.put(`${urlApi}/${data.id}`, data_response).then((response) => {
        if (response.status === 200) {
          popup.toastifySuccess(popupTextPutSuccess);
          setMyData(response.data);
          // return navigate(urlRouter);
        } else {
          popup.toastifyWarning(popupTextPutWarning);
        }
      });
    } else {
      const response = AxiosInstance.post(`${urlApi}`, data_response).then((response) => {
        if (response.status === 201) {
          // return navigate(urlRouter);
          // Popup про успішні зміни
          popup.toastifySuccess(popupTextPostSuccess);
          // alert("Дані оновлено");
          navigate(`${urlRouter}/update/${response.data.id}`);
        } else {
          popup.toastifyWarning(popupTextPostWarning);
        }
      });
    };
  };

  return (
    <>
      {/* Popup про успішні зміни (справа зверху) */}
      <ToastContainer />

      <CForm onSubmit={handleSubmit} className="mb-3">
        <div className="mb-3">
          <CFormLabel htmlFor="exampleFormControlInput1">Назва</CFormLabel>
          <CFormInput type="text" id="exampleFormControlInput1" value={name} onChange={(e) => setName(e.target.value)} autoComplete="off" />
        </div>
        <div className="mb-3">
          <CFormLabel htmlFor="exampleFormControlInput1">Опис</CFormLabel>
          <CFormInput type="text" id="exampleFormControlInput3" value={description} onChange={(e) => setDescription(e.target.value)} autoComplete="off" />
        </div>

        <CButton type="submit" color="success">Зберегти</CButton>
      </CForm>
    </>
  );
};

export default LocationsForm;