import { useState , useEffect } from "react";
import AxiosInstance from "../Axios";
import Card from 'react-bootstrap/Card';
import CardGroup from 'react-bootstrap/CardGroup';

const GalleryList = ({gallery_id, update}) => {
    const [galleryList, setGalleryList] = useState([]);
    useEffect(() => {
        AxiosInstance.get('/gallery_items', {params:{gallery: gallery_id}}).then((responce) => {
            if(responce.status == 200){
                setGalleryList(responce.data['hydra:member']);
            }
        })
    }, [gallery_id, update])
    function Deleted(row) {
        // console.log('row', row);
        confirmAlert({
          title: 'Ви впевнені що хочите видалити цей пункт ?',
          // message: 'Are you sure to do this.',
          message: (
            <>
             
            </>
          ),
          buttons: [
            {
              label: 'Так',
              onClick: () => {
                const response = AxiosInstance.delete(urlApi + '/' + row.id).then((response) => {
                  getRowsTable();
                  // Вивести popup про успішне видалення замовлення
                  popup.toastifySuccess('Видалено');
                });
              }
            },
            {
              label: 'Ні',
              onClick: () => {
                return;
              }
            }
          ]
        });
    };

    return (
        <>
        <CardGroup>
            {galleryList.map((val) => {
                return(
                    <Card style={{maxWidth: '400px'}}>
                        <Card.Img variant="top" src={process.env.REACT_APP_SERVER_URL + val.media.contentUrl} />
                        <Card.Body>
                        <Card.Title>{val.name}</Card.Title>
                        <Card.Text>{val.content}</Card.Text>
                        </Card.Body>
                        <Card.Footer>
                        <small className="text-muted">111</small>
                        </Card.Footer>
                    </Card>
                )
            })}
            
        </CardGroup>
            
        </>
    );
}

export default GalleryList;