import React, { useEffect, useState } from 'react';
import { CFormCheck, CButton } from '@coreui/react';
import AxiosInstance from '../Axios';

// Список підрозділів
const LocationsList = (props) => {
    // console.log('LocationsList props', props);

    const urlApi_1 = '/locations';
    const urlApi_2 = '/materials';

    const [locations, setLocations] = useState([]); // Список підрозділів
    const [checkedLocations, setCheckedLocations] = useState([]); // Список підрозділів з checked

    const popupTextAddSuccess = "Додані підроздали змінено";
    const popupTextDeleteSuccess = "Додані підроздали не змінено";

    // Запит за підрозділами
    const getLocations = async () => {
        try {
            const response = await AxiosInstance.get(urlApi_1);
            // console.log('response', response);
            setLocations(response.data["hydra:member"]);
        } catch (error) {
            console.error("Помилка запиту за підрозділами", error);
        };
    };

    // Функція для оновлення підрозділів
    const putSpecailties = async () => {
        let article = {
            location: checkedLocations
        };
        try {
            const response = await AxiosInstance.put(`${urlApi_2}/${props.data.id}`, article);
            // console.log("specialties put response", response);
            props.setMyData(response.data);
            // Popup про успішні зміни
            props.toastifySuccess(popupTextAddSuccess);
        } catch (error) {
            // Popup про успішні зміни
            props.toastifySuccess(popupTextDeleteSuccess);
            console.error("Додані підроздали не змінено", error);
        };
    };

    // Зробити запит за всіма підрозділами
    useEffect(() => {
        getLocations();
    }, []);

    // Відслідковувати для яких підрозділів доданий матеріал у форматі IRI
    useEffect(() => {
        const initialCheckedLocations = props.data.location.map(loc => `/api/locations/${loc.id}`);
        // console.log('initialCheckedLocations', initialCheckedLocations);
        setCheckedLocations(initialCheckedLocations);
    }, [props.data.location]);

    // Обробка зміни стану чекбоксу
    const handleCheckboxChange = (id) => {
        setCheckedLocations((prevState) => {
            const locationIRI = `/api/locations/${id}`;
            if (prevState.includes(locationIRI)) {
                // Видалити з масиву, якщо було відмічено
                return prevState.filter(loc => loc !== locationIRI);
            } else {
                return [...prevState, locationIRI];
            };
        });
    };

    return (
        <div>
            <h3 className="mb-2">Підрозділи</h3>

            {
                locations.length > 0 &&
                <>
                    {
                        locations.map((location) => {
                            // console.log('location', location);
                            const isChecked = checkedLocations.includes(`/api/locations/${location.id}`);

                            return (
                                <CFormCheck className="mb-2"
                                    id={`location-${location.id}`}
                                    label={location.name} key={location.id}
                                    checked={isChecked}
                                    onChange={() => handleCheckboxChange(location.id)}
                                />
                            );
                        })
                    }
                    <div className="mb-2">
                        <CButton color="success" onClick={() => putSpecailties()}>Зберегти</CButton>
                    </div>
                </>
            }
        </div >
    );
};

export default LocationsList;