import React, { useEffect, useRef, useState } from 'react';
import { Editor } from '@tinymce/tinymce-react';
import {
  CForm,
  CFormLabel,
  CFormInput,
  CButton,
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CRow,
  CFormSelect,
} from '@coreui/react';
import { Link, useLoaderData, useNavigate } from 'react-router-dom';
import axios from "axios";
import { formatUTC } from 'src/store';
import dayjs from 'dayjs';
import AddHeaderSlider from 'src/components/headers/AddHeaderSlider';
import AxiosInstance from 'src/components/Axios';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// import MyToastify from 'src/components/myComponents/MyToactify';
import PageInfoList from 'src/components/page-info/PageInfoList';
import ProductCategories from 'src/components/product/ProductCategories';



const tokenStr = localStorage.getItem('user');
const header = { headers: { "Authorization": `Bearer ${tokenStr}` } };

export async function LoaderProjectsUpdate({ params }) {

  if (Object.keys(params).length != 0) {
    let res = await axios.get(process.env.REACT_APP_SERVER_URL + '/api/projects/' + params.prodId, header).then((response) => {
      return response;
    });
    return res;
  }
  return false;
}
const formReducer = (state, event) => {
  return {
    ...state,
    [event.target.name]: event.target.value
  }
}
const ProjectsForm = () => {
  const { data } = useLoaderData();
  const [name, setName] = useState((data != undefined) ? data.name : '');
  const [status, setStatus] = useState((data != undefined) ? data.status : '');
  const [budget, setBudget] = useState((data != undefined) ? data.budget : '');
  // const [financed, setFinanced] = useState((data != undefined) ? data.financed : []);
  // const [partners, setPartners] = useState((data != undefined) ? data.partners : []);

  const [dateStart, setDateStart] = useState((data != undefined)? new Date(data.dateStart).toISOString() : new Date().toISOString());
  const [dateEnd, setDateEnd] = useState((data != undefined)? new Date(data.dateEnd).toISOString() : new Date().toISOString());
  const [sliders, setSliders] = useState((data != undefined && data.slider != undefined)? data.slider.match(/(\d+)/)?.[0]: false);

  const editorRef = useRef(null);
  const navigate = useNavigate();
  // Ключ для Editor
  const apiKey = process.env.REACT_APP_TINYMCE_API_KEY;

  const handleSubmit = event => {
    event.preventDefault();
    if (editorRef.current) {

      const article = {
        name: name,
        dateStart: dateStart,
        dateEnd: dateEnd,
        status: status,
        // partners: partners,
        // financed: financed,
        budget: parseInt(budget) || 0,
        content: editorRef.current.getContent(),
      };
      // console.log(article);
      if (data != undefined) {
        const response = axios.put(process.env.REACT_APP_SERVER_URL + '/api/projects/' + data.id, article, header).then(()=>{navigate('/projects');});
      } else {
        const response = axios.post(process.env.REACT_APP_SERVER_URL + '/api/projects', article, header).then(()=>{navigate('/projects');});
      }
    }
  }

  return (
    <>
      <ToastContainer />
      <CForm onSubmit={handleSubmit}>
        <AddHeaderSlider id={(sliders !== false) ? sliders : undefined} newSlider={setSliders} parentUrl={`projects/${data.id}`}/> 
        <div className="mb-3">
          <CFormLabel htmlFor="exampleFormControlInput1">Назва</CFormLabel>
          <CFormInput type="text" id="exampleFormControlInput1" value={name} onChange={(e) => setName(e.target.value)} autoComplete="off" />
        </div>
        <div className="mb-3">
        <CFormLabel htmlFor="exampleFormControlInput1">Статус</CFormLabel>
          <CFormSelect
            aria-label="Default select example"
            onChange={(e) => setStatus(e.target.value)}
            defaultValue={status}
            required
            options={[
              { label: 'Статус проекту', value: '' },
              { label: 'Completed', value: 'Completed' },
              { label: 'Planned', value: 'Planned' },
              { label: 'Pending', value: 'Pending' }
            ]}
          />
        </div>
        <div className="mb-3">
          <CFormLabel htmlFor="exampleFormControlInput1">Бюджет</CFormLabel>
          <CFormInput type="number" id="exampleFormControlInput1" value={budget} onChange={(e) => setBudget(e.target.value)} autoComplete="off" />
        </div>
        {/* <div className="mb-3">
          <CFormLabel htmlFor="exampleFormControlInput1">Фінансування</CFormLabel>
          <CFormInput type="text" id="exampleFormControlInput1" value={financed} onChange={(e) => setFinanced(e.target.value.split(','))} autoComplete="off" />
        </div>
        <div className="mb-3">
          <CFormLabel htmlFor="exampleFormControlInput1">Партненри</CFormLabel>
          <CFormInput type="text" id="exampleFormControlInput1" value={partners} onChange={(e) => setPartners(e.target.value.split(','))} autoComplete="off" />
        </div> */}

        <div className="mb-3">
            <CFormLabel htmlFor="exampleFormControlInput1">Дата створення </CFormLabel>
            <CFormInput type="date" id="exampleFormControlInput1" value={dayjs(dateStart).format('YYYY-MM-DD')} onChange={(e) => setDateStart(e.target.value)} />
        </div>
        <div className="mb-3">
            <CFormLabel htmlFor="exampleFormControlInput1">Дата завершення </CFormLabel>
            <CFormInput type="date" id="exampleFormControlInput1" value={dayjs(dateEnd).format('YYYY-MM-DD')} onChange={(e) => setDateEnd(e.target.value)} />
        </div>
        <div className="mb-3">
          <Editor
            apiKey={apiKey}
            onInit={(evt, editor) => editorRef.current = editor}
            initialValue={(data != undefined) ? data.content : ''}
            init={{
              height: 700,
              menubar: 'edit view insert format',
              plugins: [
                'advlist','autolink',
                'lists','link','image','charmap','preview','anchor','searchreplace','visualblocks','fullscreen',
                'insertdatetime','media','table', 'help', 'code'
             ],
              toolbar: 'undo redo | fontfamily fontsize | fontselect | bold italic backcolor | ' +
                'alignleft aligncenter alignright alignjustify | ' +
                'bullist numlist checklist outdent indent | removeformat | link media a11ycheck code table help',
              content_style: 'body { font-family:FixelDisplayRegular; font-size:14px }',
              font_family_formats: "Andale Mono=andale mono,times; Arial=arial,helvetica,sans-serif; Arial Black=arial black,avant garde; Book Antiqua=book antiqua,palatino; Comic Sans MS=comic sans ms,sans-serif; Courier New=courier new,courier; Georgia=georgia,palatino; Helvetica=helvetica; Impact=impact,chicago; Fixel Display Regular=fixelDisplayRegular; Symbol=symbol; Tahoma=tahoma,arial,helvetica,sans-serif; Terminal=terminal,monaco; Times New Roman=times new roman,times; Trebuchet MS=trebuchet ms,geneva; Verdana=verdana,geneva; Webdings=webdings; Wingdings=wingdings,zapf dingbats",
            }}
          />
        </div>
        <CButton type="submit" color="success">Зберегти</CButton>
      </CForm>

      {(data != undefined)&&
        <PageInfoList id={data.id} type="project" />
      }
    </>
  );
};

export default ProjectsForm;
