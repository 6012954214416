import { useEffect, useState } from "react";
import { Table } from "react-bootstrap";
import AxiosInstance from "src/components/Axios";
import Spinner from 'react-bootstrap/Spinner'
import { CCard, CCardBody, CCardHeader, CCol, CRow, CButton, CForm, CInputGroup, CFormLabel, CFormInput, CAccordion, CAccordionItem, CAccordionHeader, CAccordionBody, CFormCheck } from '@coreui/react';
import { GiConsoleController } from "react-icons/gi";
import Dropdown from 'react-bootstrap/Dropdown';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { HiOutlineDownload } from "react-icons/hi";
import dayjs from 'dayjs';


const urlApi = '/products';
const urlApi_3 = '/options';

const Prices = () => {
    const [products, setProducts] = useState([]);
    const [loader, setLoader] = useState(true);
    const [priceGT, setPrcieGT] = useState(0);
    const [show, setShow] = useState(true);
    const [category, setCategory] = useState([]);
    const [data, setData] = useState([]);
    const [date, setDate] = useState("");
    const datePrice = (date === "") ? "" : dayjs(date).format('DD.MM.YYYY');
    const dateNow = dayjs(new Date()).format('DD.MM.YYYY');
    const [isRedColorText, setIsRedColorText] = useState(false);

    const [priceGroupArr, setPriceGroupArr] = useState(""); // Для які ціни показувати

    const optionKeyForPriceGroup = "show_price_group"; // Ключ з списком які ціни показувати
    const optionKeyForPriceDate = "td-checked-gurt%3A%3Aprice_last_sync"; // Ключ для дати оновлення прайсу

    // Які ціни показувати
    const [isShowGurt_3, setIsShowGurt_3] = useState(false);
    const [isShowGurt_2, setIsShowGurt_2] = useState(false);
    const [isShowGurt_1, setIsShowGurt_1] = useState(false);
    const [isShowVIP, setIsShowVIP] = useState(false);

    // Відслідковувати які ціни показувати
    useEffect(() => {
        // console.log('priceGroupArr', priceGroupArr);
        if (priceGroupArr.length > 0) {
            const groupArr = priceGroupArr.split(',').map(el => el.trim().toLowerCase());
            // console.log('groupArr', groupArr);

            setIsShowGurt_3(groupArr.includes("3"));
            setIsShowGurt_2(groupArr.includes("2"));
            setIsShowGurt_1(groupArr.includes("1"));
            setIsShowVIP(groupArr.includes("vip"));
        };
    }, [priceGroupArr]);

    // Функція для запиту за датою прайсу та які ціни показувати
    const getDate = async (key) => {
        try {
            const response = await AxiosInstance.get(`${urlApi_3}?option_key=${key}`);
            // console.log('response', response);
            if (key === optionKeyForPriceDate) {
                setDate(response.data['hydra:member'][0].value)
            }
            if (key === optionKeyForPriceGroup) {
                setPriceGroupArr(response.data['hydra:member'][0].value)
            }
        } catch (error) {
            console.error("Помилка запиту дати прайсу:", error);
        };
    };

    // Зробити запит за датою прайсу та які ціни показувати
    useEffect(() => {
        getDate(optionKeyForPriceDate);
        getDate(optionKeyForPriceGroup);
    }, []);

    // Відслідковувати чи дата прійсу відмінна від сьогоднішньої дати
    useEffect(() => {
        if (dateNow !== "" && datePrice !== "") {
            setIsRedColorText(true);
        } else {
            setIsRedColorText(false);
        };
    }, [dateNow, datePrice]);

    const getProducts = () => {
        let params = {
            pagination: false,
            "g[0]": "product:read",
            "g[1]": "product:admin",
            "g[2]": "product:category",
        };

        if (show === true) {
            params['show'] = true;
        } else if (priceGT >= 0) {
            params['price[gt]'] = 0;
        }

        setLoader(true);

        AxiosInstance.get(urlApi, { params: params }).then((response) => {
            // console.log(response.data['hydra:member']);
            setProducts(response.data['hydra:member']);
            setLoader(false);
        });
    }

    useEffect(() => {
        AxiosInstance.get('/categories').then((response) => {
            // console.log('categories get response', response);
            setCategory(response.data['hydra:member']);
            getProducts();
        });
    }, [show])

    useEffect(() => {
        if (products.length > 0) {
            let newArray = [];
            // console.log(products);
            // console.log(category);
            for (let i = 0; i < category.length; i++) {
                let index = newArray.findIndex((n) => (n.cat_id === category[i].id));
                if (index < 0) {
                    newArray.push({ cat_id: category[i].id, cat_name: category[i].name, products: [] });
                }
            }
            // console.log(newArray);
            products.forEach((p) => {
                if (p.category != undefined && p.category.length > 0) {
                    p.category.forEach((c) => {
                        let index = newArray.findIndex((n) => (n.cat_id === c.id));
                        if (index > 0 && newArray[index] != undefined)
                            newArray[index].products.push(p);
                    })
                }
            })
            setData(newArray);
        }
    }, [products])

    const handleShowFilterChange = (event) => {
        setShow(!show)
    }

    let i = 0;
    return (
        <>
            <CRow className="mb-2">
                {/* <CCol md={2} className='filter-field mt-md-2 d-flex align-center'> */}
                <CCol md={2} className='filter-field mt-md-2 d-flex align-items-center'>
                    <CFormCheck id="exampleFormControlInput8" label="Товар продається" checked={show} onChange={(event) => handleShowFilterChange(event)} />
                </CCol>
                <CCol md={3} className='filter-field mt-md-2'>
                    {/* <a href={`${process.env.REACT_APP_SERVER_URL}/get-prices`}>Вивантажити файл з цінами</a> */}
                    <Form className="d-flex" action={`${process.env.REACT_APP_SERVER_URL}/get-prices`} method="get">
                        <Dropdown>
                            <Dropdown.Toggle variant="grey" id="dropdown-basic">
                                Виберіть категорії
                            </Dropdown.Toggle>

                            <Dropdown.Menu style={{ maxHeight: "400px", overflow: "auto", paddingRight: "5px" }}>
                                {/* <Dropdown.Item href={`${process.env.REACT_APP_SERVER_URL}/get-prices`}>Всі категорії</Dropdown.Item> */}
                                {
                                    (loader == false && data.length > 0) &&
                                    data.map((c) => {
                                        return (
                                            <Form.Check key={c.cat_id} className="ms-2" type="checkbox" name="category[]" label={c.cat_name} value={c.cat_id} />
                                            // {/* <Dropdown.Item key={c.cat_id} href={`${process.env.REACT_APP_SERVER_URL}/get-prices?category_id=${c.cat_id}`}>{c.cat_name}</Dropdown.Item> */ }
                                        )
                                    })
                                }
                            </Dropdown.Menu>
                        </Dropdown>
                        <Button variant="success" type="submit">
                            <HiOutlineDownload />
                        </Button>
                    </Form>
                </CCol>

                <CCol md={3} className='filter-field d-flex align-items-center'>
                    <span style={isRedColorText ? { color: "red" } : {}}>
                        Прайс станом на {datePrice}
                    </span>
                </CCol>
            </CRow>

            <p>Щоб не нагружати сторінку всіма товарами, виводяться тільки ті товари які мають галочку Продається, або ціну більше нуля. Якщо якоїсь позиції нема перевірте ціну або мітку продається.</p>

            {(loader == false && data.length > 0) ?
                data.map((c) => {
                    // console.log('c.cat', c);
                    return (
                        <div key={c.cat_id}>
                            <h3>{c.cat_name}</h3>
                            <Table>
                                <thead>
                                    <tr>
                                        <th>#</th>
                                        <th>Назва</th>
                                        <th>Продається</th>
                                        <th>Артикул</th>
                                        <th>Код 1с</th>
                                        <th>Прихідна ціна</th>
                                        <th>Дата прих.</th>
                                        <th>Ціна</th>
                                        {isShowGurt_3 && <th>Гурт 3</th>}
                                        {isShowGurt_2 && <th>Гурт 2</th>}
                                        {isShowGurt_1 && <th>Гурт 1</th>}
                                        {isShowVIP && <th>Vip</th>}
                                    </tr>
                                </thead>
                                <tbody>
                                    {(c.products != undefined) && c.products.map((p) => {
                                        i++;
                                        return (
                                            <tr key={p.id}>
                                                <td>{i}</td>
                                                <td>{p.name}</td>
                                                <td>{(p.show === true) ? 'так' : 'ні'}</td>
                                                <td>{p.article}</td>
                                                <td>{p.code1c}</td>
                                                <td>{p.priceIncome}</td>
                                                <td>{(p.dateLastPriceIncome === "" || p.dateLastPriceIncome === null) ? "" : dayjs(p.dateLastPriceIncome).format('DD.MM.YYYY')}</td>
                                                <td>{p.price}</td>
                                                {isShowGurt_3 && <td>{(p.priceCategory != undefined && p.priceCategory != null) && p.priceCategory.gurt_4}</td>}
                                                {isShowGurt_2 && <td>{(p.priceCategory != undefined && p.priceCategory != null) && p.priceCategory.gurt_3}</td>}
                                                {isShowGurt_1 && <td>{(p.priceCategory != undefined && p.priceCategory != null) && p.priceCategory.gurt_2}</td>}
                                                {isShowVIP && <td>{(p.priceCategory != undefined && p.priceCategory != null) && p.priceCategory.gurt_1}</td>}
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </Table>
                        </div>
                    )
                })
                :
                <>
                    <Spinner animation="grow" variant="info" />
                    <Spinner animation="grow" variant="info" />
                    <Spinner animation="grow" variant="info" />
                </>
            }
        </>
    );
}

export default Prices;

