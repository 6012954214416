import React, { useEffect, useState } from 'react';
import { useMediaQuery } from 'react-responsive';

// Значення стану акордиону в залежності від розширення екрану (відкритий/закритий)
const useOpenAccordion = () => {

    const [activeItem, setActiveItem] = useState(1); // стартове значення стану акордиону (1 - відкритий)
    const isDesktop = useMediaQuery({ minWidth: 992 }); // Відслідковувати ширину екрану

    // Відслідковувати стартове значення стану акордиону в залежності від розширення екрану
    useEffect(() => {
        // console.log('isDesktop', isDesktop);
        setActiveItem(isDesktop ? 1 : 2);
    }, [isDesktop]);

    return { activeItem };
};

export default useOpenAccordion;