import { useEffect, useState } from "react";
import AxiosInstance from "../Axios";
import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";


const urlApi = '/languages';

const LangugesPanel = ({data}) => {
    const navigate = useNavigate();

    // console.log(data);
    const [languagesList, setLaguagesList] = useState([]);
    const [language, setLaguage] = useState('');
    // const [language, setLaguage] = useState((data != undefined)? data.language.key: 'ua');
    const [translations, setTranslations] = useState((data != undefined && data.translations != undefined)? data.translations: false);

    const getLangs  = () =>{
        AxiosInstance.get(`${urlApi}?page=1`).then((response) => {
            setLaguagesList(response.data['hydra:member'])
        })
    }

    useEffect(() =>  {
        setLaguage((data != undefined &&  data.language != undefined)? data.language.name: 'ua');
        getLangs();
    }, [data]);


    const CreateTranslate  = (e, l) => {
        
        const data_response = {
            name: data.name,
            slug: `${data.slug}_${l.key}`,

          };
        data_response['language'] = `/api/languages/${l.id}`;
        data_response['translation'] = `/api/attributes/${data.id}`;
        const response = AxiosInstance.post(`/attributes`, data_response).then((response) => {
            if (response.status === 201) {
              return navigate(`/attributes/update/${response.data.id}`);
            }
          });
    }

    return (
        <div>
            {/* <div>: </div> */}
            <div>
                <ul>
                    {
                        (data != undefined && data.translation != undefined && data.translation.id !=  undefined) ?
                            <>
                                <h4>Переклад: {language}</h4>
                                <Link to={`/attributes/update/${data.translation.id}`}>Повернутися до UA</Link>
                            </>
                        :
                            <>
                                <h4>Переклад: {language}</h4>
                                {/* <li className='btn btn-secondary text-white'>{language}</li> */}
                                {languagesList.map((l) =>{
                                    let search = true;
                                    // console.log('translations', translations);
                                    if(l.name != language){
                                        if(translations != false){
                                            let t = translations.filter(t => t.language.key === l.key)
                                            if(t.length > 0)
                                                return (<Button variant="outline-secondary ml-5" ><Link to={`/attributes/update/${t[0].id}`} >{l.key}</Link></Button>);
                                            else
                                                search = false;
                                        }else{
                                            search = false;
                                        }
                                    }
                                    if(!search)
                                        return (<Button 
                                            variant="outline-secondary mr-5"
                                            onClick={(e) => {CreateTranslate(e, l)}}
                                        >
                                            додати  <b>{l.key}</b>
                                        </Button>);
                                })}
                            </>
                    }
                </ul>
            </div>
        
        </div>
    )
}

export default LangugesPanel;