import React, { useEffect, useState } from 'react';
import { CFormCheck, CButton } from '@coreui/react';
import AxiosInstance from '../Axios';

// Список спеціальностей
const SpecialtyList = (props) => {
    // console.log('SpecialtyList props', props);

    const urlApi_1 = '/specialties';
    // const urlApi_2 = '/materials';
    // const urlApi_3 = '/events';

    const [specialties, setSpecialties] = useState([]); // Список спеціальностей
    const [checkedSpecialties, setCheckedSpecialties] = useState([]); // Список спеціальностей з checked

    const popupTextAddSuccess = "Додані спеціальності змінено";
    const popupTextDeleteSuccess = "Додані спеціальності не змінено";

    // Запит за підрозділами
    const getSpecialties = async () => {
        try {
            const response = await AxiosInstance.get(urlApi_1);
            // console.log('response', response);
            setSpecialties(response.data["hydra:member"]);
        } catch (error) {
            console.error("Помилка запиту за спеціальностями", error);
        };
    };

    // Функція для оновлення спеціальностей
    const putSpecailties = async () => {
        let article = {
            specialty: checkedSpecialties
        };
        try {
            // const response = await AxiosInstance.put(`${urlApi_2}/${props.data.id}`, article);
            const response = await AxiosInstance.put(`${props.urlPage}/${props.data.id}`, article);
            // console.log("specialties put response", response);
            props.setMyData(response.data);
            // Popup про успішні зміни
            props.toastifySuccess(popupTextAddSuccess);
        } catch (error) {
            // Popup про успішні зміни
            props.toastifySuccess(popupTextDeleteSuccess);
            console.error("Додані спеціальності не змінено", error);
        };
    };

    // Зробити запит за всіма підрозділами
    useEffect(() => {
        getSpecialties();
    }, []);

    // Відслідковувати для яких спеціальностей доданий матеріал у форматі IRI
    useEffect(() => {
        if (props?.data?.specialty) {
            const initialCheckedSpecialties = props.data.specialty.map(spec => `/api/specialties/${spec.id}`);
            // console.log('initialCheckedSpecialties', initialCheckedSpecialties);
            setCheckedSpecialties(initialCheckedSpecialties);
        };
    }, [props.data.specialties]);

    // Обробка зміни стану чекбоксу
    const handleCheckboxChange = (id) => {
        setCheckedSpecialties((prevState) => {
            const specialtiesIRI = `/api/specialties/${id}`;
            if (prevState.includes(specialtiesIRI)) {
                // Видалити з масиву, якщо було відмічено
                return prevState.filter(spec => spec !== specialtiesIRI);
            } else {
                return [...prevState, specialtiesIRI];
            };
        });
    };

    return (
        <div>
            <h3 className="mb-2">Спеціальності</h3>

            {
                specialties.length > 0 &&
                <>
                    {
                        specialties.map((specialty) => {
                            // console.log('specialty', specialty);
                            const isChecked = checkedSpecialties.includes(`/api/specialties/${specialty.id}`);

                            return (
                                <CFormCheck className="mb-2"
                                    id={`specialty-${specialty.id}`}
                                    label={specialty.name} key={specialty.id}
                                    checked={isChecked}
                                    onChange={() => handleCheckboxChange(specialty.id)}
                                />
                            );
                        })
                    }
                    <div className="mb-2">
                        <CButton color="success" onClick={() => putSpecailties()}>Зберегти</CButton>
                    </div>
                </>
            }
        </div >
    );
};

export default SpecialtyList;