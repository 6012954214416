import React, { useEffect, useRef, useState } from 'react';
import { Editor } from '@tinymce/tinymce-react';
import {
  CForm,
  CFormLabel,
  CFormInput,
  CButton
} from '@coreui/react';
import { Link, useLoaderData, useNavigate } from 'react-router-dom';
import axios from "axios";
import PermissionsNoPage, { Permissions } from "src/components/Permissions";
import AxiosInstance from 'src/components/Axios';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import MyToastify from 'src/components/myComponents/MyToactify';
import { Alert } from 'react-bootstrap';
import NewsMedia from 'src/components/news/NewsMedia';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import PageInfoList from 'src/components/page-info/PageInfoList';


const urlRouter = '/post';
const apiKey = process.env.REACT_APP_TINYMCE_API_KEY;
const urlApi = '/posts';
export async function LoaderBlogUpdate({ params }) {
  if (Object.keys(params).length != 0 && params.id != undefined) {
    let res = await AxiosInstance.get(`${urlApi}/${params.id}`).then((response) => {
      response['type'] = (params.type != undefined) ? params.type : "post";
      // console.log("posts/id/ get response", response);
      return response;
    });
    return res;
  };

  return { type: (params.type != undefined) ? params.type : 'post', data: undefined };
};


const formReducer = (state, event) => {
  return {
    ...state,
    [event.target.name]: event.target.value
  }
}

const blogForm = ({ user_data }) => {
  if (!Permissions(user_data, 'Blog', 'VIEW_BLOG')) {
    return <PermissionsNoPage />;
  }
  const { data, type } = useLoaderData();
  const [name, setName] = useState((data != undefined) ? data.name : '');
  const [pageType, setPageType] = useState((type != undefined) ? type : 'page');

  const [newsDate, setNewsDate] = useState((data != undefined && data.dateEntered != undefined) ? new Date(data.dateEntered).toISOString() : new Date().toISOString());
  const navigate = useNavigate();

  const popup = MyToastify();
  const editorRef = useRef(null);
  const handleSubmit = event => {
    event.preventDefault();
    if (editorRef.current) {
      console.log(editorRef.current.getContent());
      const article = {
        name: name,
        // content: newsShot,
        dateEntered: newsDate,
        type: pageType,
        content: editorRef.current.getContent(),
      };
      if (data != undefined) {
        const response = AxiosInstance.put('/posts/' + data.id, article).then((response) => {
          popup.toastifySuccess('Пост оновлено');
          if (type == undefined || type == 'page' || type == '')
            return navigate(`${urlRouter}/update/${response.data.id}`);
          else
            return navigate(`/post/${type}/${response.data.id}`);
        });
      } else {
        const response = AxiosInstance.post('/posts', article).then((response) => {
          popup.toastifySuccess('Пост оновлено');
          if (type == undefined || type == 'post' || type == '')
            return navigate(`${urlRouter}/update/${response.data.id}`);
          else
            return navigate(`/post/${type}/${response.data.id}`);
        });
      }
    }
  }

  const datePicker = (value) => {
    let formattedDate = value.toISOString();
    // console.log(typeof formattedDate);
    // values['dateOrder'] = formattedDate;
    // let name='dateOrder';
    // setValues({
    //     ...values,
    //     [name]: formattedDate
    // });
    // console.log(formattedDate);
    setNewsDate(formattedDate);
  }



  return (
    <>
      <CForm onSubmit={handleSubmit}>
        <div className="mb-3">
          <CFormLabel htmlFor="exampleFormControlInput1">Назва</CFormLabel>
          <CFormInput type="text" id="exampleFormControlInput1" value={name} onChange={(e) => setName(e.target.value)} />
        </div>
        <div className="mb-3">
          <CFormLabel htmlFor="exampleFormControlInput1">Дата</CFormLabel>
          <DatePicker
            className="form-control"
            dateFormat="yyyy-MM-dd"
            selected={new Date(newsDate)}
            onChange={(date) => datePicker(date)}
          />
        </div>
        <div className="mb-3">
          <CFormLabel htmlFor="exampleFormControlInput1">Тип</CFormLabel>
          <CFormInput type="text" id="exampleFormControlInput2" value={pageType} onChange={(e) => setPageType(e.target.value)} autoComplete="off" disabled={(type == undefined || type == 'page') ? false : true} />
        </div>
        <div className="mb-3">
          <Editor
            apiKey={apiKey}
            onInit={(evt, editor) => editorRef.current = editor}
            initialValue={(data != undefined) ? data.content : ''}
            init={{
              height: 500,
              menubar: 'edit view insert format',
              plugins: [
                'advlist', 'autolink',
                'lists', 'link', 'image', 'charmap', 'preview', 'anchor', 'searchreplace', 'visualblocks', 'fullscreen',
                'insertdatetime', 'media', 'table', 'help', 'code'
              ],
              toolbar: 'undo redo | fontfamily fontsize | fontselect | bold italic backcolor | ' +
                'alignleft aligncenter alignright alignjustify | ' +
                'bullist numlist checklist outdent indent | removeformat | link media a11ycheck code table help',
              content_style: 'body { font-family:FixelDisplayRegular; font-size:14px }',
              font_family_formats: "Andale Mono=andale mono,times; Arial=arial,helvetica,sans-serif; Arial Black=arial black,avant garde; Book Antiqua=book antiqua,palatino; Comic Sans MS=comic sans ms,sans-serif; Courier New=courier new,courier; Georgia=georgia,palatino; Helvetica=helvetica; Impact=impact,chicago; Fixel Display Regular=fixelDisplayRegular; Symbol=symbol; Tahoma=tahoma,arial,helvetica,sans-serif; Terminal=terminal,monaco; Times New Roman=times new roman,times; Trebuchet MS=trebuchet ms,geneva; Verdana=verdana,geneva; Webdings=webdings; Wingdings=wingdings,zapf dingbats",
            }}
          />
        </div>
        <CButton type="submit" color="success">Submit</CButton>
      </CForm>
      {(data != undefined) &&
        <PageInfoList id={data.id} type="posts" />
      }
    </>
  );
};

export default blogForm;
