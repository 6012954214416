/* eslint-disable prettier/prettier */
import React, { useEffect, useRef, useState } from 'react';
import { Editor } from '@tinymce/tinymce-react';
import { CForm, CFormLabel, CFormInput, CButton } from '@coreui/react';
import { useNavigate } from "react-router-dom";
import AxiosInstance from '../Axios';
import { transliterate } from 'transliteration';
import Select from 'react-select'

const keys = [
    { value: 'carbohydrates ', label: 'Вуглеводи', lang: 'ua' },
    { value: 'nutritional ', label: 'Харчова цінність', lang: 'ua' },
    { value: 'volume', label: 'Мінімальний об`єм замовлення', lang: 'ua' },
    { value: 'producer', label: 'Виробник', lang: 'ua' },
    { value: 'suh_ch', label: 'Масова часка сухих речовин – не менше', lang: 'ua' },
    { value: 'sug_ch', label: 'Масова частка сахарози,%, не менше', lang: 'ua' },
    { value: 'sum_ch', label: 'Масова частка суми цукрів, що зброджуються, %, не менше', lang: 'ua' },
    { value: 'ph_val', label: 'Величина рН', lang: 'ua' },

    { value: 'carbohydrates_en', label: 'Carbohydrate content', lang: 'en' },
    { value: 'nutritional_en', label: 'Nutritional value', lang: 'en' },
    { value: 'volume_en', label: 'Minimal order quantity', lang: 'en' },
    { value: 'producer_en', label: 'Producer', lang: 'en' },
    { value: 'suh_ch_en', label: 'Mass fraction of dry substances - not less than', lang: 'en' },
    { value: 'sug_ch_en', label: 'Mass fraction of sucrose', lang: 'en' },
    { value: 'sum_ch_en', label: 'The mass fraction of the sum of sugars', lang: 'en' },
    { value: 'ph_val_en', label: 'Size рН', lang: 'en' },

    { value: 'carbohydrates_dn', label: 'Kohlenhydrate', lang: 'dn' },
    { value: 'nutritional_dn', label: 'Nährwert', lang: 'dn' },
    { value: 'volume_dn', label: 'Mindestbestellmenge', lang: 'dn' },
    { value: 'producer_dn', label: 'Produzent', lang: 'dn' },
    { value: 'suh_ch_dn', label: 'Massenanteil der Trockensubstanz, %, nicht weniger', lang: 'dn' },
    { value: 'sug_ch_dn', label: 'Der Massenanteil von Saccharose, %, nicht weniger', lang: 'dn' },
    { value: 'sum_ch_dn', label: 'Der Massenanteil der Summe der vergärbaren Zucker, %, nicht weniger', lang: 'dn' },
    { value: 'ph_val_dn', label: 'Größe pH-Wert', lang: 'dn' },
]

const AddProductInfo = ({ product, actionShowModal, setUpdate, toastifySuccess, toastifyWarning, currentLanguage }) => {
    const urlApi = '/product_infos';

    // Текст для popup
    const popupTextSuccess = "Інформація про товар додана";
    const popupTextWarning = "Така інформація про товар вже присутня";

    const [values, setValues] = useState({
        product: `/api/products/${product}`,
        name: "",
        value: "",
        keyName: "",
        sort: 0
    });

    // console.log("currentLanguage", currentLanguage);

    const handleChange = (value, name) => {
        if (name == 'keyName') {
            setValues({
                ...values,
                keyName: value.value,
                name: value.label,
            });
        } else {
            setValues({
                ...values,
                [name]: value
            });
        }
    };

    const handleSubmit = event => {
        event.preventDefault();
        let v = true;
        let vError = '';
        // console.log(values);
        if (values.name == '') {
            v = false;
            vError += "-> Назва\n";
        }
        if (values.value == '') {
            v = false;
            vError += "-> Значення\n";
        }
        if (values.sort === 0) {
            v = false;
            vError += "-> Сортування\n";
        }

        if (!v) {
            toastifyWarning("Ви не заповнили обовязкові поля:\n" + vError);
        } else {
            AxiosInstance.get(`${urlApi}?product.id=${product}&name=${values.name}`).then((response) => {
                if (response.status === 200) {
                    // console.log("response", response);
                    if (response.data['hydra:totalItems'] === 0) {
                        // Додати інформацію про товар
                        AxiosInstance.post(urlApi, values).then((response) => {
                            if (response.status == 201) {
                                // Popup про успішні зміни
                                toastifySuccess(popupTextSuccess);
                                // return actionShowModal(false);
                                actionShowModal(false);
                                setUpdate(true);
                            }
                        });
                    } else {
                        // Popup про попередження про неуспішне додавання інформації
                        toastifyWarning(popupTextWarning);
                    }
                };
            });
        };
    };

    return (
        <>
            <CForm onSubmit={handleSubmit}>
                <div className='row'>
                    <div className='col-4'>
                        <div className="mb-3">
                            <CFormLabel htmlFor="exampleFormControlInput1">Назва</CFormLabel>
                            <Select
                                options={keys.filter(key => key.lang === currentLanguage)}
                                onChange={(value) => {
                                    handleChange(value, 'keyName');
                                }}
                            />
                        </div>
                    </div>
                    <div className='col-4'>
                        <div className="mb-3">
                            <CFormLabel htmlFor="exampleFormControlInput1">Значення</CFormLabel>
                            <CFormInput type="text" id="exampleFormControlInput2" value={values.value} onChange={(e) => handleChange(e.target.value, 'value')} />
                        </div>
                    </div>
                    <div className='col-4'>
                        <div className="mb-3">
                            <CFormLabel htmlFor="exampleFormControlInput1">Сортування</CFormLabel>
                            <CFormInput type="text" id="exampleFormControlInput2" value={values.sort} onChange={(e) => handleChange(parseInt(e.target.value) || 0, 'sort')} />
                        </div>
                    </div>
                </div>
                <CButton type="submit" color="success">Зберегти</CButton>
            </CForm>
        </>
    )
};

export default AddProductInfo;