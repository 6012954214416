// import { createStore } from 'redux'

// const initialState = {
//   sidebarShow: true,
// }

// const changeState = (state = initialState, { type, ...rest }) => {
//   switch (type) {
//     case 'set':
//       return { ...state, ...rest }
//     default:
//       return state
//   }
// }

// const store = createStore(changeState)
// export default store

const dateOptions = {
    timeZone: 'UTC',
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
};
  
//   const timeOptions = {
//     timeZone: 'UTC',
//     hour: 'numeric', 
//     minute: 'numeric',
// };

export const formatUTC = (strDate, dateOptions) => {
    const date = new Date(strDate);
    const datetoUTC = date.toLocaleDateString('uk-UA', dateOptions);
    // const timetoUTC = date.toLocaleTimeString('uk-UA', timeOptions);
    return `${datetoUTC}`;
}