import React, { useEffect, useRef, useState } from 'react';
import AxiosInstance from 'src/components/Axios';
import AsyncSelect from 'react-select/async';

// Для відображення назви користувача в полі AsyncSelect
const getProductName = (p) => {
    let n = (p.name != undefined) ? `${p.name} ` : '';
    let b = (p.barcode != undefined) ? `${p.barcode} ` : '';
    let a = (p.article != undefined) ? ` (${p.article})` : '';
    let c = (p.code1c != undefined) ? ` (${p.code1c})` : '';
    return `${n} | ${b} | ${a} | ${c}`;
};

const FilterProduct = ({ setData, clear, spec, url }) => {
    const [selectedOption, setSelectedOption] = useState(null);

    // Для автоматичного фокуса при відкритті сторінки
    const searchRef = useRef();
    useEffect(() => {
        if (url !== undefined && url === "orders/update/id/") {
            searchRef.current.focus();
        };
    }, []);

    useEffect(() => {
        if (clear) {
            // console.log(clear);
            setSelectedOption(null);
        }
    }, [clear])
    // Для поля Користувач (AsyncSelect)
    const loadOptions = async (inputValue) => {
        return await AxiosInstance.get(`/products/filter`, {
            params: {
                type: (spec === true) ? '1' : '0',
                search: inputValue
            }
        }).then((response) => {
            return response.data.map((result) => (
                {
                    label: getProductName(result),
                    value: result.id,
                }));
        });
    };
    const handleChange = (selectedOption) => {
        setData(selectedOption);
        setSelectedOption(selectedOption);
    };

    return (
        <>
            <AsyncSelect
                cacheOptions
                loadOptions={loadOptions}
                defaultOptions
                value={selectedOption}
                onChange={handleChange}
                className="flex-grow-1"
                placeholder="Товар"
                // onKeyDown={keyDown}
                ref={searchRef}
            />
        </>
    )
}

export default FilterProduct;